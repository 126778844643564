import React, { useState } from 'react';
import { Modal, Box, Button, Typography, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import config from '../../../../config';
import { paymentApi } from '../../../../api/services/userApi';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const promoCodePlans = config.promoCodePlans as Record<string, { id: string }>;

const validationSchema = Yup.object({
  promoCode: Yup.string()
    .required('Promo code is required')
    .test('isValidPromo', 'Invalid promo code', (value) => {
      return value ? Boolean(promoCodePlans[value]) : false;
    }),
});

type PropsType = {
  open: boolean;
  handleClose: ()=>void;
  onSubmit: (priceId: string)=>void;
};

const PromoCodeModal:React.FC<PropsType> = ({ open, handleClose, onSubmit }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handelSubmit = async (
    values: { promoCode: string },
    { setSubmitting } : {setSubmitting: (value: boolean) => void},
  ) => {
    try {
      setSubmitting(true);
      await paymentApi.deleteSubscription();
      setIsSubmitted(true);
      setTimeout(() => {
        onSubmit(promoCodePlans[values.promoCode].id);
        setSubmitting(false);
        setIsSubmitted(false);
        handleClose();
      }, 1000);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
      setIsSubmitted(false);
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="promo-modal-title">
      <Box sx={modalStyle}>
        <Typography id="promo-modal-title" variant="h6" component="h2" gutterBottom>
          Enter Promo Code
        </Typography>
        <Formik
          initialValues={{ promoCode: '' }}
          validationSchema={validationSchema}
          onSubmit={handelSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field
                as={TextField}
                name="promoCode"
                label="Promo Code"
                fullWidth
                error={touched.promoCode && Boolean(errors.promoCode)}
                helperText={touched.promoCode && errors.promoCode}
                margin="normal"
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
                sx={{
                  mt: 2,
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                }}
              >
                {isSubmitting ? 'Validating...' : 'Apply Promo Code'}
              </Button>

              {isSubmitted && !errors.promoCode && (
                <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
                  Promo code applied successfully!
                </Typography>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PromoCodeModal;
