/* eslint-disable */

import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as LogoIcon } from '../../assets/icons/SkipTracing-Logo.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter 1.svg';
import { ReactComponent as InstagramIcon  } from '../../assets/icons/instagram.svg';
import { StyledFooter, StyledMobileFooter } from './Footer.styles';

const Icons = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <FacebookIcon />
      <TwitterIcon />
      <InstagramIcon />
    </div>
  )
}

const Logo = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <LogoIcon />
    </div>
  )
}

const Links = ({ className }: { className: string }) => {
  const linksArray = [
    { link: '/terms', title: 'Terms of Service' },
    { link: '/privacy', title: 'Privacy Policy' },
    { link: '/fulfillment', title: 'Fulfillment Policy' },
    { link: '/personal-info', title: 'Personal Information' },
    {
      link: 'mailto:support@easybuttonskiptrace.com',
      title: 'support@easybuttonskiptrace.com',
    },
  ];
  
  return (
    <div className={className}>
      {linksArray.map((item, index) => (
        <Link
          key={index}
          to={item.link}
          className="styled-footer__link"
          onClick={(e) => {
            if (item.link.startsWith('mailto:')) {
              window.location.href = item.link;
              e.preventDefault();
            }
          }}
        >
          {item.title}
        </Link>
      ))}
    </div>
  )
};

const Title = ({ className }: { className: string }) => {
  return (
    <div className="center-curve-container">
      <p className={className}>
        ©Easy Button Leads LLC 2024. All rights reserved.
      </p>
    </div>
  )
}

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
    {isMobile ? (
      <StyledMobileFooter>
        <div className="container">  
          <Logo className="logo-container" />
          <Links className="links-container" />
          <Icons className="social-media-icons-container" />
          <Title className="footer-label-text" />
        </div>
      </StyledMobileFooter>
    ) : (
      <StyledFooter>
        <div className="container">
          <Logo className="left-logo-container" />
          <Title className="center-curve-container-text" />
          <Icons className="social-media-icons-right-container" />
          <Links className="links-container" />
        </div>
      </StyledFooter>
    )  
    }
    </>
  );
};

export default Footer;
