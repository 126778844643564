/* eslint-disable */

import TableArea from './components/TableArea/TableArea';
import TableAreaOrderAdmin from './components/TableAreaOrderAdmin/TableAreaOrderAdmin';
import TableAreaUserAdmin from './components/TableAreaUserAdmin/TableAreaUserAdmin';
import TableAreaCodeAdmin from './components/TableAreaCodeAdmin/TableAreaCodeAdmin';
import AuthorizedPage from './pages/AuthorizedPage/AuthorizedPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import LogInPage from './pages/LogInPage/LogInPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import FulfillmentPage from './pages/FulfillmentPage/FulfillmentPage';
import Amount from './pages/PaymentsPage/components/Amount/Amount';
import PaymentFinalOrder from './pages/PaymentsPage/components/PaymentFinalOrder/PaymentFinalOrder';
import PaymentsInfoArea from './pages/PaymentsPage/components/PaymentsInfoArea/PaymentsInfoArea';
import PaymentsPage from './pages/PaymentsPage/PaymentsPage';
import PrivateRoute from './pages/PrivateRoute/PrivateRoute';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import SignUpPagePart1 from './pages/SignUpPage/components/SignUpPagePart1/SignUpPagePart1';
import SignUpPagePart2 from './pages/SignUpPage/components/SignUpPagePart2/SignUpPagePart2';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import UnauthorizedPage from './pages/UnauthorizedPage/UnauthorizedPage';
import ImportListPage from './pages/ImportListPage/ImportListPage';
import HowToUseSitePage from './pages/HowToUseSitePage/HowToUseSitePage';
import SubscriptionPage from './pages/SubscriptionPage';
import AffiliatePage from './pages/AffiliatePage';

export const UploadFilePointsArray = [
  {
    title: 'Upload Your File',
    value: 'upload-file',
  },
  {
    title: 'Review Order',
    value: 'mapping-data',
  },
  {
    title: 'Order',
    value: 'order',
  },
];

export const PaymentPointsArray = [
  {
    title: 'Enter amount',
    value: 'enter-amount',
  },
  {
    title: 'Payment',
    value: 'payment',
  },
  {
    title: 'Order',
    value: 'order',
  },
];

export const arrRoutes = [
  {
    path: '/sign-up',
    element: (
      <PrivateRoute isNeedAuth={false}>
        <UnauthorizedPage>
          <SignUpPage />
        </UnauthorizedPage>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <SignUpPagePart1 />,
      },
      {
        path: 'part-2',
        element: <SignUpPagePart2 />,
      },
    ],
  },
  {
    path: '/log-in',
    element: (
      <PrivateRoute isNeedAuth={false}>
        <UnauthorizedPage>
          <LogInPage />
        </UnauthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <PrivateRoute isNeedAuth={false}>
        <UnauthorizedPage>
          <ResetPasswordPage />
        </UnauthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/import-list',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <ImportListPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/affiliate',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <AffiliatePage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/subscription',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <SubscriptionPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/how-to-use-site',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <HowToUseSitePage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <DashboardPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <TableArea />,
      },
    ],
  },
  {
    path: '/payments',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <PaymentsPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <PaymentsInfoArea />,
      },
      {
        path: 'enter-amount',
        element: <Amount />,
      },
      {
        path: 'order',
        element: <PaymentFinalOrder />,
      },
    ],
  },
  {
    path: '/orders',
    element: (
      <PrivateRoute isNeedAuth isNeedAdmin>
        <AuthorizedPage>
          <DashboardPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <TableAreaOrderAdmin />,
      },
    ],
  },
  {
    path: '/users',
    element: (
      <PrivateRoute isNeedAuth isNeedAdmin>
        <AuthorizedPage>
          <DashboardPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <TableAreaUserAdmin />,
      },
    ],
  },
  {
    path: '/codes',
    element: (
      <PrivateRoute isNeedAuth isNeedAdmin>
        <AuthorizedPage>
          <DashboardPage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: <TableAreaCodeAdmin />,
      },
    ],
  },
  {
    path: '/profile',
    element: (
      <PrivateRoute isNeedAuth>
        <AuthorizedPage>
          <ProfilePage />
        </AuthorizedPage>
      </PrivateRoute>
    ),
  },
  {
    path: '/fulfillment',
    element: <FulfillmentPage />,
  },

  {
    path: '/*',
    element: (
      <PrivateRoute isNeedAuth>
        <NotFoundPage />
      </PrivateRoute>
    ),
  },
];
