import React from 'react';
import StyledGhostButton from './GhostButton.styles';

type PropsType = {
  onClick?: () => void;
  type?: 'submit' | 'button';
  className?: string;
  disabled?: boolean;
} & React.PropsWithChildren;

const GhostButton: React.FC<PropsType> = (props) => {
  return (
    <StyledGhostButton
      onClick={props?.onClick}
      type={props?.type}
      disabled={props?.disabled}
      className={props?.className ?? ''}
    >
      {props.children}
    </StyledGhostButton>
  );
};

export default GhostButton;
