import styled from 'styled-components';

export default styled.header`

  display: flex;
  column-gap: 10px;
  align-items: center;

  .avatar-area{
    cursor: pointer;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: ${({ theme }) => theme.mainTheme.color.red.lightBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.mainTheme.color.red.light};

    h3 {
      color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .avatar-text{
    font-weight: bold;
    text-transform: capitalize;
  }
`;
