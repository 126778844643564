import styled, { css } from 'styled-components';

export default styled.div<{ isDragActive: boolean }>`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 48px;
  font-family: ${({ theme }) => theme.font.family};
  ${({ theme, isDragActive }) => css`
    cursor: ${isDragActive ? 'grabbing' : 'default'};
    color: ${theme.mainTheme.color.typography.greyText};
    border: 2px dashed ${isDragActive ? theme.mainTheme.color.red.light : theme.mainTheme.color.grey.background};
    background: ${isDragActive ? theme.mainTheme.color.red.lightBackground : theme.mainTheme.color.background};
  `}

  .styled-drag-and-drop-area__picture {
    color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};

    &-green {
      color: #72CA95;
    }
  }

  .styled-drag-and-drop-area__text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
    text-align: center;

    h1 {
      color: ${({ theme }) => theme.mainTheme.color.grey.dark};
    }

    p {
      color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    }
  }

  .styled-drag-and-drop-area__spinner {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 88px;
      height: 88px;
      margin: 6px;
      border: 6px solid ${({ theme }) => theme.mainTheme.color.green};
      border-radius: 50%;
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${({ theme }) => theme.mainTheme.color.green} transparent transparent transparent;

      :nth-child(1) {
        animation-delay: -0.45s;
      }

      :nth-child(2) {
        animation-delay: -0.3s;
      }

      :nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
