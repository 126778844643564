/* eslint-disable */
import React from 'react';
import { Button, Box, useTheme } from '@mui/material';
import { ReactComponent as CSVIcon } from '../../assets/icons/CSV.svg';
import GhostButton from '../GhostButton/GhostButton';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as TrashBinIcon } from '../../assets/icons/trash-bin.svg';

type PropsType = {
  onClick?: () => void;
  className?: string;
  isLoading: boolean;
  isFileUpload: boolean;
  fileInfo: { name: string; size: number };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteFile: () => void;
} & React.PropsWithChildren;

const FileUpload: React.FC<PropsType> = (props) => {
  const theme = useTheme()
  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const resultSize = formatBytes(props.fileInfo.size);

  if (props.isLoading) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <div className="styled-drag-and-drop-area__spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div>
        <h2>Uploading</h2>
        <small className="text-1">{resultSize}</small>
        </div>
      </Box>
    );
  }

  if (props.isFileUpload) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <CSVIcon className="styled-drag-and-drop-area__picture-green" height={80} />
        <div>
        <h2>Uploaded</h2>
        <small className="text-1">{resultSize}</small>
        </div>
        <GhostButton onClick={props.deleteFile}>
          {/* <p className="text-3">Delete</p> */}
          <TrashBinIcon />
        </GhostButton>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap={5}>
      <CSVIcon className="styled-drag-and-drop-area__picture" />
      <div className="styled-drag-and-drop-area__text-area">
        <h1>You don’t have any traces yet! Select .csv to Upload</h1>
        <p className="text-1">or drag and drop here</p>
      </div>
      <Button
        endIcon={<UploadIcon fill={theme.palette.primary.main} />}
        sx={{
          px: 2,
          py:1,
          borderRadius: 2,
          textTransform: 'capitalize',
        }}
        variant="text"
      >
        <label id="label-file-upload" htmlFor="input-file-upload">
          <input
            type="file"
            id="input-file-upload"
            onChange={props.handleChange}
            accept=".csv"
            hidden={true}
          />
          <p className="text-3">Upload File</p>
        </label>
      </Button>
    </Box>
  );
};

export default FileUpload;
