import { useFormik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import { useAppDispatch } from '../../../../store/hooks';
import { resetPassSchema } from '../../../../types/validationSchemes';
import userThunks from '../../../../store/user/userThunk';
import StyledResetPassword from './PutNewPasswordPage.styles';

type PropsType = {
  onSubmit: (value: number) => void;
  email: string;
};

const PutNewPasswordPage: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();

  const formikPassword = useFormik({
    initialValues: { email: props.email, newPassword: '', repeatNewPassword: '', currentPassword: '1' },
    validationSchema: resetPassSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(userThunks.resetPasswordThirdPart(values)).unwrap();
        props.onSubmit(4);
      } catch (err) {
        toast.error(err);
      }
    },
  });

  return (
    <StyledResetPassword>
      <form onSubmit={formikPassword.handleSubmit}>
        <div className="styled-reset-page__content">
          <div className="styled-reset-page__title-area">
            <p className="website-header">change password</p>
            <p className="text-2 styled-reset-page__title-area__description">New password can’t repeat none of your old passwords.</p>
          </div>

          <div className="styled-reset-page__main-content">
            <div className="styled-reset-page__inputs-area">
              <Input
                nameInput="newPassword"
                title="New password"
                placeHolder="Enter new password"
                onChange={formikPassword.handleChange}
                value={formikPassword.values.newPassword}
                password
                displayIcon
                displayPasswordCounter
              />
              <Input
                nameInput="repeatNewPassword"
                title="Repeat new password"
                placeHolder="Enter new password"
                onChange={formikPassword.handleChange}
                value={formikPassword.values.repeatNewPassword}
                error={formikPassword.errors.repeatNewPassword}
                isTouched={formikPassword.touched.repeatNewPassword}
                onBlur={formikPassword.handleBlur}
                password
                displayIcon
              />
            </div>
          </div>

          <Button type="submit">
            <h4>change password</h4>
          </Button>

        </div>
      </form>
    </StyledResetPassword>
  );
};

export default PutNewPasswordPage;
