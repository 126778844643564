/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { StatusCodes } from 'http-status-codes';
import { Typography, Box, Button } from '@mui/material';
import StyledPaymentsInfoArea from './PaymentsInfoArea.styles';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import BalanceTableArea from '../../../../components/BalanceTableArea/BalanceTableArea';
import { paymentApi } from '../../../../api/services/userApi';
import userThunk from '../../../../store/user/userThunk';
import PaymentDetails from '../PaymentDetails';
import PageTitle from '../../../../components/PageTitle';

const PaymentsInfoArea = () => {
  const dispatch = useAppDispatch();
  const userCards = useAppSelector((state) => state.userSlice.cards);
  const paymentMethod = userCards.length > 0 ? userCards[0] : undefined;

  useEffect(() => {
    (async () => {
      try {
        await dispatch(userThunk.getCards()).unwrap();
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch]);

  const onAddCard = async () => {
    try {
      const res = await paymentApi.createCard();
      if (res.status === StatusCodes.OK) {
        const link = document.createElement('a');
        link.href = res.data.data?.session.url || '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledPaymentsInfoArea>
      {
        paymentMethod ? (
          <PageTitle title="Payments" />
        ) : (
          <PageTitle title="Payments" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', gap: 2 }}>
            <Button
              endIcon={<PlusIcon fill="white" />}
              sx={{
                px: 4,
                py: 1.5,
                borderRadius: 2,
                textTransform: 'capitalize',
              }}
              variant="contained"
              onClick={onAddCard}
            >
              Add New Card
            </Button>
          </PageTitle>
        )
      }
      {
        paymentMethod && <PaymentDetails paymentMethod={paymentMethod} />
      }
      <Box>
        <Typography
          sx={{
            textTransform: 'capitalize',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '1px',
            marginBottom: 1,
          }}
        >
          Payment details
        </Typography>
        <BalanceTableArea />
      </Box>
    </StyledPaymentsInfoArea>
  );
};

export default PaymentsInfoArea;
