export const checkIsEmailErrorMessage = (err: string) => {
  return /email/i.test(err) || /user/i.test(err);
};

export const checkIsCodeErrorMessage = (err: string) => {
  return /code/i.test(err);
};

export const checkIsPasswordErrorMessage = (err: string) => {
  return /password/i.test(err);
};

export const checkIsSuccessfulErrorMessage = (err: string) => {
  return /successful/i.test(err);
};

export const checkIsBalanceErrorMessage = (err: string) => {
  return /balance/i.test(err);
};

export const checkIsCountRecordsErrorMessage = (err: string) => {
  return /record/i.test(err);
};

export const checkIsCouponErrorMessage = (err: string) => {
  return /coupon/i.test(err);
};
