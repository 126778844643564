export const convertFileToBase64 = async (file: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  const convertedFile: string = await new Promise((resolve) => {
    const onComplete = (result: string) => {
      return resolve(result);
    };

    reader.onload = async () => {
      onComplete(reader.result! as string);
    };
  });

  return convertedFile;
};
