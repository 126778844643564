import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .dashboard-container__search-area {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  .button-content {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: center;

    .icon {
      fill: ${({ theme }) => theme.mainTheme.color.typography.whiteText};
    }
  }

  .date-area {
    position: relative;
  }

  .date-picker-area {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-picker{
      background-color: ${({ theme }) => theme.mainTheme.color.background};
      width: 45%;
      display: flex;
      align-items: center;
      padding: 13px 20px;
      justify-content: space-between;
      border: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    .default-text {
      color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    }
  }
`;
