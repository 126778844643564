import React from 'react';
import StyledNotFoundPage from './NotFoundPage.styles';

const NotFoundPage = () => {
  return (
    <StyledNotFoundPage>
      <h2 className="website-header">Not found</h2>
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
