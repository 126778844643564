import React from 'react';
import StyledTable from './Table.styles';
import type { FilterType, TableHeadType } from '../../types/filterTypes';
import type { OrderType } from '../../types/orderTypes';
import type { PaymentType, UserType } from '../../types/userTypes';
import TableHead from '../TableHead';
import TableBody from '../TableBody';
import type { CodeType } from '../../types/codeTypes';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
  mainArray: Array<OrderType | UserType | PaymentType | CodeType>;
  tableHead: Array<TableHeadType>;
};

const Table: React.FC<PropsType> = (props) => {
  return (
    <StyledTable>
      <table>
        <thead>
          <TableHead
            filter={props.filter}
            setFilter={props.setFilter}
            tableHead={props.tableHead}
          />
        </thead>
        <TableBody
          filter={props.filter}
          setFilter={props.setFilter}
          mainArray={props.mainArray}
          length={props.tableHead.length}
        />
      </table>
    </StyledTable>
  );
};

export default Table;
