import React from 'react';
import StyledFulfillmentdPage from './FulfillmentPage.styles';

/* eslint-disable */ 
const FulfillmentPage = () => {
  return (
    <StyledFulfillmentdPage>

    
<div className='WordSection1'>

<p className='MsoNormal'><b>Fulfillment Policy for Easy Button Skip Trace</b></p>

<p className='MsoNormal'>At Easy Button Skip Trace, we strive to provide exceptional skip tracing services to our customers. Our fulfillment policy outlines our procedures regarding refunds and cancellations to ensure a transparent and satisfactory experience for all users.</p>

<p className='MsoNormal'><b>1. Subscription Activation</b></p>

<p className='MsoNormal'>Upon completing the subscription purchase for Easy Button Skip Trace, you will receive immediate access to the full range of services included in your selected plan. Access is provided through your registered account, and it is your responsibility to ensure that the account details you provide are accurate and up-to-date.</p>

<p className='MsoNormal'><b>2. No Refund Policy</b></p>

<p className='MsoNormal'>Due to the nature of our service, all subscription purchases are final and non-refundable. Once payment is processed, no refunds will be issued for any unused portion of the subscription term, regardless of usage, cancellation, or early termination.</p>


<p className='MsoNormal'><b>3. Refund Eligibility</b></p>
<p className='MsoNormal'>Refunds will only be considered under the following circumstances:</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Service Failure:</b> If Easy Button Skip Trace is unable to deliver the services as outlined due to a system failure or significant downtime, and we are unable to resolve the issue within a reasonable time frame, you may be eligible for a prorated refund.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Duplicate Payment:</b> If a duplicate payment is made for the same service, you will be eligible for a refund of the duplicate charge.</p>

<p className='MsoNormal'>To request a refund in either case, you must contact our customer support team within 30 days of the charge or the service disruption. Refunds will be processed within 10 business days after verification.</p>

<p className='MsoNormal'><b>4. Account Responsibility</b></p>
<p className='MsoNormal'>As a user, you are responsible for maintaining the security of your account and ensuring that the service is used in compliance with our Terms of Service. Any issues arising from misuse or unauthorized access to your account are your responsibility.</p>

<p className='MsoNormal'><b>5. Subscription Renewal and Cancellation</b></p>
<p className='MsoNormal'>Subscriptions are set to automatically renew at the end of each billing cycle unless canceled by the user. You can manage or cancel your subscription at any time through your account settings. If you cancel, your access will remain active until the end of the current billing period. No refunds or credits will be provided for partial periods or unused features.</p>

<p className='MsoNormal'><b>6. Service Delivery</b></p>
<p className='MsoNormal'>Easy Button Skip Trace guarantees access to its SaaS platform upon payment confirmation. We make every effort to ensure continuous and reliable service. However, occasional maintenance or unforeseen issues may result in temporary disruptions. Easy Button Skip Trace is not responsible for downtime due to circumstances beyond our control.</p>

<p className='MsoNormal'><b>7. Dispute Resolution</b></p>
<p className='MsoNormal'>If you experience technical issues with the service, our customer support team is available to assist and resolve any concerns. In the event of a billing dispute, you must contact our support team within 30 days of the charge to resolve the issue. Failure to do so within this period may result in forfeiture of the right to dispute the charge.</p>

<p className='MsoNormal'><b>8. Modifications to the Policy</b></p>
<p className='MsoNormal'>We reserve the right to modify this fulfillment policy at any time, with any changes taking effect immediately upon posting. It is your responsibility to review this policy periodically to stay informed about any updates.</p>

<p className='MsoNormal'>By utilizing our skip tracing services, customers agree to
adhere to the terms outlined in this fulfillment policy.</p>

<p className='MsoNormal'>For further assistance or inquiries regarding our
fulfillment policy, please contact our customer support team at <a
href="mailto:support@easybuttonskiptrace.com">support@easybuttonskiptrace.com</a></p>

<p className='MsoNormal'>&nbsp;</p>

</div>



    </StyledFulfillmentdPage>

  );
};

export default FulfillmentPage;
