import styled from 'styled-components';

export const StyledMobileImageContainer = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  height: 188px;
  background: linear-gradient(
      95.55deg,
      rgba(34, 34, 34, 0) 85.12%,
      #a2461f 131.01%
    ),
    linear-gradient(0deg, #222222, #222222);
  position: relative;

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 107px;
    background: ${({ theme }) => theme.mainTheme.color.bgColor};
    border-radius: 0px 0px 15px 0px;
    position: relative;

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-text {
        font-size: 32px;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: 3%;
      }

      .sub-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .sub-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .social-media-container {
      position: absolute;
      bottom: -60px;
      left: 30%;
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
  }

  @media (max-width: 768px) {
    display: flex; /* Show on small screens */
  }
`;
