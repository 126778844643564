import styled from 'styled-components';
import userAvatarImg from '../../assets/icons/user-avatar.svg';

export default styled.div`
  padding: 40px 30px;
  display: flex;
  justify-content: center;
  width: 100%;

  .styled-signup-page__container {
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 26px;
    border-radius: 20px;
    background: ${({ theme }) => theme.mainTheme.color.background};

    &__title-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;
      text-align: center;

      &__title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 0.72px;
        text-transform: uppercase;
      }

      &__description {
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0.45px;
        color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      }
    }

    &__part2 {
      &__title-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        text-align: center;

        &__description {
          color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
        }
      }

      &__main-content {
        max-width: 628px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        &__inputs-area {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 24px;
          row-gap: 24px;

          @media (max-width: 650px) {
            grid-template-columns: unset;
          }

          &__last-inputs {
            display: flex;
            column-gap: 24px;
          }
        }

        &__avatar-area {
          display: flex;
          column-gap: 24px;

          &__avatar-image {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            background: ${({ theme }) => theme.mainTheme.color.red.lightBackground};
            background-image: url(${userAvatarImg});
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }

            svg {
              stroke: ${({ theme }) => theme.mainTheme.color.typography.greyText};
              object-fit: cover;
            }
          }

          &__avatar-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 12px;
            color: ${({ theme }) => theme.mainTheme.color.typography.greyText};

            &__input-file {
              display: none;
            }

            > div {
              display: flex;
              align-items: center;
              column-gap: 30px;

              > div {
                display: flex;
                align-items: center;
                column-gap: 8px;
                color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
                cursor: pointer;

              }
            }
          }
        }
      }
    }

    &__main-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 26px;

      &__inputs-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }

    &__check-area {
      width: 100%;
      column-gap: 8px;
      display: flex;

      &__links-area {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 4px;

        &__link {
          cursor: pointer;
          text-decoration: none;
          fill: ${({ theme }) => theme.mainTheme.color.red.primary};
          color: ${({ theme }) => theme.mainTheme.color.red.primary};
        }
      }
    }
  }

`;
