import React, { useEffect } from 'react';
import StyledTableAreaCodeAdmin from './TableAreaCodeAdmin.styles';
import Table from '../Table';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Pagination from '../Pagination';
import userThunk from '../../store/user/userThunk';
import { changeUserFilter } from '../../store/user/userSlice';
import { codeAdminTableHead } from '../../store/code/codeAdminTableHead';

const TableAreaCodeAdmin = () => {
  const userSlice = useAppSelector((state) => state.userSlice);
  const dispatch = useAppDispatch();

  const onChangeFilter = (title: string, value: string | Date | null) => {
    dispatch(changeUserFilter({ title, value }));
  };

  const handleChangePage = (page: number) => {
    dispatch(changeUserFilter({ title: 'page', value: `${page}` }));
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(userThunk.getAdminCodes(userSlice.filter)).unwrap();
      } catch (error) {
        console.error(error);
      }
    })();
  }, [
    dispatch,
    userSlice.filter,
  ]);

  return (
    <StyledTableAreaCodeAdmin>
      {
        userSlice.addition.isLoading ? (
          <div className="styled-app__loader-mini" />
        ) : (
          <>
            <Table
              filter={userSlice.filter}
              setFilter={onChangeFilter}
              mainArray={userSlice.codes}
              tableHead={codeAdminTableHead}
            />
            {
              userSlice?.options ? (
                <Pagination
                  currentPage={userSlice?.options.page}
                  totalCount={userSlice?.options.totalPages}
                  pageSize={userSlice?.options.perPage}
                  onPageChange={handleChangePage}
                  countItems={userSlice?.options.totalRecords}
                />
              ) : null
            }
          </>
        )
      }
    </StyledTableAreaCodeAdmin>
  );
};

export default TableAreaCodeAdmin;
