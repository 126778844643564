import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { Button, Box } from '@mui/material';
import StyledAmount from './Amount.styles';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import Input from '../../../../components/Input/Input';
import { amountSchema } from '../../../../types/validationSchemes';
import UploadingBulkProgress from '../../../../components/UploadingBulkProgress';
import { PaymentPointsArray } from '../../../../routes';
import GhostButton from '../../../../components/GhostButton/GhostButton';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setAmountPayment } from '../../../../store/user/userSlice';
import { paymentApi } from '../../../../api/services/userApi';
import userThunk from '../../../../store/user/userThunk';

const Amount = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cards = useAppSelector((state) => state.userSlice.cards);
  const formik = useFormik({
    initialValues: { amount: '' },
    validationSchema: amountSchema,
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        const currentAmount = +values.amount * 100;
        dispatch(setAmountPayment(+values.amount));
        if (!cards.length) {
          const res = await paymentApi.checkoutSession(currentAmount);
          if (res.status === StatusCodes.OK) {
            const link = document.createElement('a');
            link.href = res.data.data?.session.url || '';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {
          await paymentApi.autoPay({ amount: currentAmount, id: cards[0].id });
          await dispatch(userThunk.loginByToken()).unwrap();
          navigate('/payments/order');
        }
      } catch (err) {
        toast.error(err);
      }
    },
  });

  const toFixedTo2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.indexOf('.') !== -1) {
      // eslint-disable-next-line no-param-reassign
      e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 3);
    }
  };

  return (
    <StyledAmount>
      <Box width="100%">
        <GhostButton onClick={() => navigate('/payments')}>
          <ArrowLeftIcon />
          <h4>Back to Payments</h4>
        </GhostButton>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <UploadingBulkProgress pointsArray={PaymentPointsArray} />
        </Box>
      </Box>

      <div className="styled-amount__container">
        <div className="styled-amount__container__title">
          <h3>Enter amount</h3>
          <p className="text-2">Enter the amount you want to top up your balance</p>
        </div>
        <form onSubmit={formik.handleSubmit} className="styled-amount__container__form">
          <div className="styled-amount__container__main-content">
            <Input
              nameInput="amount"
              title="Enter the amount"
              placeHolder="$"
              type="number"
              step="0.01"
              min="0"
              onInput={toFixedTo2}
              onChange={formik.handleChange}
              value={formik.values.amount}
              error={formik.errors.amount}
              isTouched={formik.touched.amount}
              onBlur={formik.handleBlur}
            />
            {
              !formik.errors.amount?.length || !formik.touched.amount ? (
                <p className="help-text">The value must be more or equal to $10</p>
              ) : null
            }
          </div>
          <div>
            <Button endIcon={<ArrowRightIcon fill="white" />} sx={{ borderRadius: 2, py: 1.5, px: 4 }} type="submit" variant="contained">
              <h4>Next</h4>
            </Button>
          </div>
        </form>
      </div>
    </StyledAmount >
  );
};

export default Amount;
