import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Typography, Button } from '@mui/material';
import StyledDashBoardHeader from './DashBoardHeader.styles';
import { useAppSelector } from '../../../../store/hooks';
import { useLocationName } from '../../../../utils/checkLocationHelper';

const DashBoardHeader = () => {
  const navigate = useNavigate();
  const userProfile = useAppSelector((store) => store.userSlice.user);
  const userBulks = useAppSelector((state) => state.userSlice?.bulks);
  const totalBulk = userBulks.countFile || 0;
  const pendingBulk = userBulks.countPending || 0;
  const locationName = useLocationName();
  const isOrderPage = locationName === 'orders';

  const userBalance = useMemo(() => {
    if (userProfile.balance) {
      return userProfile.balance.toFixed(2);
    }
    return 0;
  }, [userProfile.balance]);

  return (
    <StyledDashBoardHeader>
      {userProfile.isSuperUser ? (
        <Box
        sx={{
          display: 'flex',
          gap: 3,
          flexDirection: { xs: 'column', lg: 'row' },
          width: '100%',
        }}
        >
          <Card
            sx={{ p: 4, borderRadius: 3, width: { xs: '100%', lg: '50%' } }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 900,
                letterSpacing: '0.72px',
                textTransform: 'uppercase',
              }}
            >
              Total {locationName}
            </Typography>
            <Box sx={{ display: 'flex', gap: 5, marginTop: 2 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 300,
                    letterSpacing: '0.45px',
                    textTransform: 'capitalize',
                  }}
                >
                  Total {locationName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    letterSpacing: '0.72px',
                  }}
                >
                  {totalBulk}
                </Typography>
              </Box>
              {
                isOrderPage ? (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '15px',
                        fontWeight: 300,
                        letterSpacing: '0.45px',
                        textTransform: 'capitalize',
                      }}
                    >
                      Pending {locationName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontWeight: 700,
                        letterSpacing: '0.72px',
                      }}
                    >
                      {pendingBulk}
                    </Typography>
                  </Box>
                ) : null
              }
            </Box>
          </Card>
        </Box>
      ) : null}
      {!userProfile.isSuperUser ? (
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            flexDirection: { xs: 'column', lg: 'row' },
            width: '100%',
          }}
        >
          <Card
            sx={{ p: 4, borderRadius: 3, width: { xs: '100%', lg: '55%', xl: '50%' } }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, flexWrap: 'wrap' }}>
              <Box>
                <Typography
                  sx={{
                    marginBottom: 2,
                    fontSize: '24px',
                    fontWeight: 900,
                    letterSpacing: '0.72px',
                    textTransform: 'uppercase',
                  }}
                >
                  Balance
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 300,
                    letterSpacing: '0.45px',
                    textTransform: 'capitalize',
                  }}
                >
                  Current Balance
                </Typography>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    letterSpacing: '0.72px',
                  }}
                >
                  $ {userBalance}
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{
                    px: 6,
                    py: 1.5,
                    borderRadius: 2,
                    textTransform: 'capatilize',
                  }}
                  variant="contained"
                  onClick={() => navigate('/payments/enter-amount')}
                >
                  Add Balance
                </Button>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{ p: 4, borderRadius: 3, width: { xs: '100%', lg: '45%', xl: '35%' } }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 900,
                letterSpacing: '0.72px',
                textTransform: 'uppercase',
              }}
            >
              Total Skip Trace
            </Typography>
            <Box sx={{ display: 'flex', gap: 5, marginTop: 2 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 300,
                    letterSpacing: '0.45px',
                    textTransform: 'capitalize',
                  }}
                >
                  Total Bulk
                </Typography>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    letterSpacing: '0.72px',
                  }}
                >
                  {totalBulk}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 300,
                    letterSpacing: '0.45px',
                    textTransform: 'capitalize',
                  }}
                >
                  Pending Bulk
                </Typography>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    letterSpacing: '0.72px',
                  }}
                >
                  {pendingBulk}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      ) : null}
    </StyledDashBoardHeader>
  );
};

export default DashBoardHeader;
