/* eslint-disable no-nested-ternary */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { usePathIncludeLocation } from '../../utils/checkLocationHelper';
import StyledPrivateRoute from './PrivateRoute.styles';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer/Footer';

type PropsType = {
  to?: string;
  isNeedAuth: boolean;
  isNeedAdmin?: boolean;
  children: JSX.Element;
};

const PrivateRoute: React.FC<PropsType> = (props) => {
  const isSignUpSecondPart = usePathIncludeLocation('sign-up/part-2');
  const isProfilePage = usePathIncludeLocation('/profile');
  const { children } = props;
  const userEmail = useAppSelector((state) => state.userSlice.user.email);
  const isAdminUser = useAppSelector((state) => state.userSlice.user.isSuperUser);
  if (props.isNeedAuth) {
    return userEmail
      ? (
        (isAdminUser === !!props?.isNeedAdmin || isProfilePage) ? (
          <>
            <Navbar />
            <StyledPrivateRoute>
              {children}
            </StyledPrivateRoute>
            <Footer />
          </>
        ) : (
          <Navigate to={isAdminUser ? '/orders' : '/dashboard'} replace />
        )

      )
      : <Navigate to={props.to || '/log-in'} replace />;
  }
  return (isSignUpSecondPart && userEmail) || (!userEmail && !isSignUpSecondPart)
    ? (
      <StyledPrivateRoute>
        {children}
      </StyledPrivateRoute>
    ) : <Navigate to={isAdminUser ? '/orders' : '/dashboard'} replace />;
};

export default PrivateRoute;
