import styled, { css } from 'styled-components';

export default styled.div<{ displayIcon?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;

  .styled-input__container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .styled-input__svg {
    position: absolute;
    right: 20px;
    top: auto;
    padding-left: 10px;
    border-left: 1px solid ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    ${({ theme }) => css`
      stroke: ${theme.mainTheme.color.red.primary};
      color: ${theme.mainTheme.color.red.primary};
    `}
  }

  .styled-input__input {
    width: 100%;
    height: 48px;
    padding:0 20px 0 30px;
    border-radius: 10px;
    outline: none;
    ${({ theme }) => css`
      font-family: ${theme.font.family};
      background: ${theme.mainTheme.color.background};
      border: 1px solid ${theme.mainTheme.color.grey.background};
      color: ${theme.mainTheme.color.grey.dark};
    `}
    ${({ displayIcon }) => displayIcon && css`
      padding-right: 50px;
    `}

    :focus {
      border: 1px solid ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    }

    ::placeholder {
      color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    }
  }
`;
