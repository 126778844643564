import React from 'react';
import StyledPoint from './Point.styles';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

type PropsType = {
  className?: string;
  title: string;
  index: number;
  currentPoint?: number;
} & React.PropsWithChildren;

const Point: React.FC<PropsType> = (props) => {
  const { title, index, currentPoint } = props;
  if (currentPoint) {
    if (index < currentPoint) {
      return (
        <StyledPoint>
          <div className="styled-select__icon-container">
            <CheckIcon className="styled-select__icon-container__icon" />
          </div>
          <p className="text-4 done">{title}</p>
        </StyledPoint>
      );
    }
  }

  if (index === currentPoint) {
    return (
      <StyledPoint>
        <div className="styled-select__container">
          <div className="styled-select__container__default-point styled-select__container__current-point" />
        </div>
        <p className="text-4 primary">{title}</p>
      </StyledPoint>
    );
  }

  return (
    <StyledPoint>
      <div className="styled-select__container">
        <div className="styled-select__container__default-point" />
      </div>
      <p className="text-4">{title}</p>
    </StyledPoint>
  );
};

export default Point;
