import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Card,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

type PropType = {
  open?: boolean;
  onClose?: () => void;
  onClickPayBulkSkip: () => void;
  sectionArray: {
    title: string;
    info: React.ReactNode;
  }[];
  isChecked: boolean;
  handleCheckboxChange: ()=> void;
};

const BillModal: React.FC<PropType> = ({
  open,
  onClose,
  onClickPayBulkSkip,
  sectionArray,
  isChecked,
  handleCheckboxChange,
}) => {
  return (
    <Modal open={open || false} sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
      <Card
        sx={{
          maxWidth: '450px',
          borderRadius: 4,
          p: 3,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb={2}
          pb={1}
          sx={{ borderBottom: '1px solid', borderBottomColor: 'secondary.main' }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '24px',
              fontWeight: 900,
              lineHeight: '36px',
              letterSpacing: '0.03em',
              textAlign: 'left',
            }}
          >
            CONFIRM
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          {sectionArray.map((section, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', mr: 1, maxWidth: '60%' }}
              >
                {section.title}
              </Typography>
                {section.info}
            </Box>
          ))}
        </Box>

        <FormControlLabel
          control={(<Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          )}
          label={(
            <Typography variant="body2" sx={{ color: '#222222' }}>
              I have read and agreed with the{' '}
              <Link
                href="/fulfillment"
                target="_blank"
                color="primary"
              >
                Fulfillment Policy
              </Link>
            </Typography>
          )}
          sx={{ my: 3 }}
        />

        <Button
          variant="contained"
          disabled={!isChecked}
          fullWidth
          sx={{ py: 1.5, borderRadius: 2 }}
          onClick={onClickPayBulkSkip}
        >
          Submit
        </Button>
      </Card>
    </Modal>
  );
};

export default BillModal;
