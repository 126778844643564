import styled from 'styled-components';

export default styled.div`
  height: 100vh;
  margin-top: 25px;
  margin-bottom: 100px;
  max-width: 300px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 10px 0px 30px;
  margin-left: 25px;
  background: linear-gradient(
      181deg,
      #222222 2.76%,
      #e35011 178.12%,
      #a21f22 259.72%
    ),
    linear-gradient(19.77deg, rgba(34, 34, 34, 0) 141.98%, #ca8722 192.91%);


  .menu-img{
    display: flex;
    justify-content: flex-start;
    margin-right: 100px;
  }
  
  .styled-menu__main-content {
    height: 100%;
    width: 100%;

    &__touched-area {
      display: flex;
      flex-direction: column;
    }

    &__item {
      cursor: pointer;
      padding: 14px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      color: ${({ theme }) => theme.mainTheme.color.grey.dark};
    }

    .selected {
      border-radius: 4px;
      border: 1px solid #61473c;
      background: #222222;
    
  }

  .menu-item-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.mainTheme.color.typography.whiteText};
  }
`;
