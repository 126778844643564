import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { orderAPI } from '../../api/services/orderApi';
import type { FilterType } from '../../types/filterTypes';
import type { FieldType, UploadAdminOrderRequestType, UploadOrderRequestType } from '../../types/orderTypes';
import { getCurrentFilter } from '../../utils/changeFilterRequestHelper';
import { errorReturn } from '../../utils/errorReturnHelper';
import { setLoading, unsetLoading } from './orderSlice';

const getOrders = createAsyncThunk(
  'order/get',
  async (values: FilterType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const currentFilter = getCurrentFilter(values);
      const res = await orderAPI.getOrders(currentFilter);
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const getAdminOrders = createAsyncThunk(
  'order/getAdmin',
  async (values: FilterType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const currentFilter = getCurrentFilter(values);
      const res = await orderAPI.getAdminOrders(currentFilter);
      if (res.status === StatusCodes.OK) {
        return res.data.data;
      }
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const uploadFile = createAsyncThunk(
  'order/upload',
  async (values: UploadOrderRequestType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const data = await orderAPI.uploadFile(values);
      return data;
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const uploadAdminFile = createAsyncThunk(
  'order/upload-admin-file',
  async (values: UploadAdminOrderRequestType, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const data = await orderAPI.uploadAdminFile(values);
      return data;
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const deleteFile = createAsyncThunk(
  'order/delete',
  async (values: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const data = await orderAPI.deleteFile(values);
      return data;
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

const uploadFields = createAsyncThunk(
  'order/upload-fields',
  async (values: Array<FieldType>, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading());
      const data = await orderAPI.uploadFields(values);
      return data;
    } catch (err) {
      return rejectWithValue(errorReturn(err));
    } finally {
      dispatch(unsetLoading());
    }
  },
);

export default {
  getOrders,
  getAdminOrders,
  uploadFile,
  deleteFile,
  uploadAdminFile,
  uploadFields,
};
