import React, { useRef, useContext, useMemo } from 'react';
import { useDay } from '@datepicker-react/hooks';
import clsx from 'clsx';
import DatepickerContext from './datepickerContext';
import { StyledDay } from './DatePicker.styles';

type PropsType = {
  dayLabel: string;
  date: Date;
  isDisabled?: boolean;
};

const Day: React.FC<PropsType> = (props) => {
  const { dayLabel, date } = props;
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const currentColorDay = useMemo(() => {
    const returnClassNames = {
      background: 'normal-color',
      button: 'default-background-day',
    };

    if (props.isDisabled) {
      returnClassNames.background = 'default-background-day';
      returnClassNames.button = 'disabled-color';
      return returnClassNames;
    } if (isSelectedStartOrEnd) {
      returnClassNames.background = 'selected-background-day';
      returnClassNames.button = 'selected-first-or-last-color';
      return returnClassNames;
    } if (isSelected) {
      returnClassNames.background = 'selected-background-day';
      returnClassNames.button = 'selected-color';
      return returnClassNames;
    } if (isWithinHoverRange) {
      returnClassNames.background = 'selected-background-day';
      returnClassNames.button = 'selected-color';
      return returnClassNames;
    }
    return returnClassNames;
  }, [isSelected, isSelectedStartOrEnd, isWithinHoverRange, props.isDisabled]);

  if (!dayLabel) {
    return null;
  }

  return (
    <StyledDay isDisabled={props?.isDisabled} className={currentColorDay.background ?? ''}>
      <button
        disabled={props?.isDisabled}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        tabIndex={tabIndex}
        type="button"
        ref={dayRef}
        className={clsx(['help-text', currentColorDay.button])}
      >
        {dayLabel}
      </button>
    </StyledDay>
  );
};

export default Day;
