import React, { useMemo } from 'react';
import StyledUploadingBulkProgress from './UploadingBulkProgress.styles';
import Point from '../Point';
import { useLocationSecondName } from '../../utils/checkLocationHelper';

type PropsType = {
  pointsArray: Array<{ title: string; value: string }>;
  className?: string;
} & React.PropsWithChildren;

const UploadingBulkProgress: React.FC<PropsType> = (props) => {
  const currentLocation = useLocationSecondName();
  const currentPoint = useMemo(() => {
    return props.pointsArray.findIndex((item) => item.value === currentLocation);
  }, [currentLocation, props.pointsArray]);
  const isDisplayLine = (index: number) => {
    return index < props.pointsArray.length - 1;
  };

  return (
    <StyledUploadingBulkProgress>
      {props.pointsArray.length ? props.pointsArray.map((point, index) => (
        <div key={index} className="styled-uploading-bulk-progress__point">
          <Point
            title={point.title}
            index={index}
            currentPoint={currentPoint}
          />
          {isDisplayLine(index) ? <div className="styled-uploading-bulk-progress__point__line" /> : null}
        </div>
      )) : null}
    </StyledUploadingBulkProgress>
  );
};

export default UploadingBulkProgress;
