import styled from 'styled-components';

export default styled.div`
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .styled-hr {
    height: 1px;
    background: ${({ theme }) => theme.mainTheme.color.grey.background};
    border: none;
  }
`;
