import React from 'react';
import { Outlet } from 'react-router-dom';
import StyledSignUpPage from './SignUpPage.styles';

const SignUpPage = () => {
  return (
    <StyledSignUpPage>
      <Outlet />
    </StyledSignUpPage>
  );
};

export default SignUpPage;
