import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Link as MUILink, Typography, Box } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import Input from '../../../../components/Input/Input';
import StyledResetPassword from './PutEmailPage.styles';

type PropsType = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  isTouched?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const PutEmailPage: React.FC<PropsType> = (props) => {
  return (
    <StyledResetPassword>
      <Box component="form" onSubmit={props.onSubmit}>
        <Box className="styled-reset-page__content">
          <Box className="styled-reset-page__title-area">
            <Typography className="styled-reset-page__title-area__title">Forget Your Password?</Typography>
          </Box>

          <Box className="styled-reset-page__main-content">
            <Box className="styled-reset-page__inputs-area">
              <Input
                nameInput="email"
                placeHolder="Email"
                onChange={props.onChange}
                value={props.email}
                error={props?.error}
                isTouched={props?.isTouched}
                onBlur={props?.onBlur}
              />
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="body2">Not a member?</Typography>
              <MUILink component={Link} underline="hover" to="/sign-up">
                <Typography variant="body2">Create an account</Typography>
              </MUILink>
            </Box>
          </Box>

          <Button sx={{ py: 2, borderRadius: 2, textTransform: 'none' }} type="submit" variant="contained" fullWidth>
            Send Reset Password Instruction
          </Button>

          <MUILink display="flex" gap={1} component={Link} underline="hover" to="/log-in">
            <ChevronLeft />
            <Typography>Back to Sign In</Typography>
          </MUILink>
        </Box>
      </Box>
    </StyledResetPassword>
  );
};

export default PutEmailPage;
