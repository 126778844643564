/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import StyledTableHead from './TableHead.styles';
import { ReactComponent as SortingIcon } from '../../assets/icons/sorting.svg';
import { ReactComponent as UpIcon } from '../../assets/icons/up.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/down.svg';
import type { FilterType, TableHeadType } from '../../types/filterTypes';
import useComponentVisible from '../../utils/useComponentVisible';
import DropDownListStatus from '../DropDownListStatus/DropDownListStatus';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
  tableHead: Array<TableHeadType>;
};

const TableHead: React.FC<PropsType> = (props) => {
  const wrapperRef = useRef(null);
  const [isOpenDropDownList, setIsOpenDropDownList] = useState(false);

  const onOpen = () => {
    setIsOpenDropDownList(!isOpenDropDownList);
  };

  const onClose = () => {
    setIsOpenDropDownList(false);
  };

  useComponentVisible(wrapperRef, onClose);

  const changeSorting = (nameSorting: string) => {
    if (!(props.filter.sortField === nameSorting)) {
      props.setFilter('sortField', nameSorting);
      props.setFilter('sortDirect', 'ASC');
    } else {
      if (props.filter.sortDirect === 'ASC') {
        props.setFilter('sortDirect', 'DESC');
      }
      if (props.filter.sortDirect === 'DESC') {
        props.setFilter('sortField', '');
        props.setFilter('sortDirect', '');
      }
    }
  };

  const getCurrentSortingColor = (nameSorting: string) => {
    return props.filter.sortField === nameSorting ? `${props.filter.sortDirect.toLowerCase()}-color-icon` : 'default-color-icon';
  };

  const displaySortingIcon = (item: TableHeadType) => (
    item.isSort ? (
      <SortingIcon
        className={getCurrentSortingColor(item.name) ?? ''}
      />
    ) : null
  );

  const displayFilterIcon = (item: TableHeadType) => (
    item.isFilter ? (
      !isOpenDropDownList
        ? <DownIcon className="default-color-icon" />
        : (
          <UpIcon className="default-color-icon" />
        )
    ) : null
  );

  const handleClick = (item: TableHeadType) => {
    if (item.isFilter) {
      onOpen();
    } else if (item.isSort) {
      changeSorting(item.name);
    }
  };

  return (
    <StyledTableHead>
      {props.tableHead.length
        ? props.tableHead.map((item, index) => (
          <th className={clsx({ 'label-text': index, 'text-2': !index })} key={index}>
            <div ref={item.isFilter ? wrapperRef : null}>
              <div
                className={clsx(['styled-table-head__title', 'styled-table-head__dropdown-list', { 'styled-table-head__title-pointer': item.isFilter || item.isSort }])}
                onClick={() => handleClick(item)}
              >
                {item.title}
                {displaySortingIcon(item)}
                {displayFilterIcon(item)}
              </div>
              {(item.isFilter && isOpenDropDownList) ? (
                <DropDownListStatus filter={props.filter} setFilter={props.setFilter} />
              ) : null}
            </div>
          </th>
        )) : null
      }
    </StyledTableHead>
  );
};

export default TableHead;
