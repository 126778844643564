import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  .payments-container__section {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
    justify-content: space-between;
    align-items: flex-start;

    > :last-child {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .rc-scrollbars-view {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 20px;
      position: relative !important;
      overflow: hidden !important;
      padding-bottom: 10px;
      margin: 0 !important;
    }

    .rc-scrollbars-track {
      right: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
    }

    &__cards-area {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 20px;
    }

  }
`;
