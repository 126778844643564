import React from 'react';
import StyledModal from './Modal.styles';

type PropsType = {
  className?: string;
} & React.PropsWithChildren;

const Modal: React.FC<PropsType> = (props) => {
  return (
    <StyledModal
      className={props?.className ?? ''}
    >
      <div
        className="styled-modal__container"
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
    </StyledModal>
  );
};

export default Modal;
