import dayjs from 'dayjs';

export const datesSelect = [
  {
    title: 'Today',
    startDateRange: new Date(dayjs().startOf('d').toString()),
    endDateRange: new Date(dayjs().startOf('d').toString()),
  },
  {
    title: 'Yesterday',
    startDateRange: new Date(dayjs().startOf('d').subtract(1, 'day').toString()),
    endDateRange: new Date(dayjs().startOf('d').subtract(1, 'day').toString()),
  },
  {
    title: 'Last 7 Days',
    startDateRange: new Date(dayjs().startOf('d').subtract(6, 'day').toString()),
    endDateRange: new Date(dayjs().startOf('d').toString()),
  },
  {
    title: 'Last 30 Days',
    startDateRange: new Date(dayjs().startOf('d').subtract(29, 'day').toString()),
    endDateRange: new Date(dayjs().startOf('d').toString()),
  },
  {
    title: 'This Month',
    startDateRange: new Date(dayjs().startOf('month').toString()),
    endDateRange: new Date(dayjs().startOf('d').toString()),
  },
  {
    title: 'Last Month',
    startDateRange: new Date(dayjs().subtract(1, 'month').startOf('month').toString()),
    endDateRange: new Date(dayjs().subtract(1, 'month').endOf('month').startOf('d')
      .toString()),
  },
  {
    title: 'Custom Range',
    startDateRange: null,
    endDateRange: null,
  },
];
