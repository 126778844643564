import React from 'react';
import Button from '../Button';
import GhostButton from '../GhostButton';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import Modal from '../Modal';

type PropsType = {
  onClick?: () => void;
  className?: string;
  onCloseModal?: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

} & React.PropsWithChildren;

const ErrorCountRecordsModal: React.FC<PropsType> = (props) => {
  return (
    <Modal>
      <h3 className="styled-modal__container__text">Not enough entries in the document</h3>
      <h1 className="styled-modal__container__text">The document must have at least 100 records to go to the next step</h1>
      <div className="styled-modal__container__buttons-area">
        <GhostButton onClick={props.onCloseModal}>
          <p className="text-3">Cancel</p>
        </GhostButton>
        <Button>
          <label id="label-file-upload" htmlFor="input-file-upload">
            <input
              type="file"
              id="input-file-upload"
              onChange={props.handleChange}
              accept=".csv"
            />
            <h4>Upload File</h4>
            <UploadIcon />
          </label>
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorCountRecordsModal;
