import React from 'react';
import StyledSearchInput from './SearchInput.styles';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

type PropsType = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: string;
  placeHolder?: string;
  nameInput: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const SearchInput: React.FC<PropsType> = (props) => {
  return (
    <StyledSearchInput
      className={props?.className ?? ''}
      displayIcon
    >
      <div className="styled-input__container">
        <input
          type="text"
          name={props.nameInput}
          className="styled-input__input text-2"
          id={props.nameInput}
          onChange={props.onChange}
          value={props.value}
          placeholder={props?.placeHolder}
          autoComplete="on"
          onBlur={props?.onBlur}
        />
        <SearchIcon className="styled-input__svg" />
      </div>

    </StyledSearchInput>
  );
};

export default SearchInput;
