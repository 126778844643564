import styled from 'styled-components';

export const StyledLoginWebFrameContainer = styled.div`
  position: relative;
  max-width: 1520px;

  .login-frame {
    width: 100%;
    height: auto;
    clip-path: polygon(33% 0, 100% 0, 100% 100%, 0% 100%, 0% 30%, 30% 30%);
  }

  .positioned-ghost-button {
    position: absolute;
    top: 16%;
    left: 20%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @media (max-width: 1100px) {
      top: 10%;
      left: 25%;
    }
  }

  .log-in-text {
    font-size: 60px;
    font-weight: 900;
    line-height: 69px;
    letter-spacing: 1.8px;

    @media (max-width: 868px) {
      font-size: 32px;
      line-height: 45px;
    }
  }

  .small-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
