import React from 'react';
import StyledCheckPasswordPoint from './CheckPasswordPoint.styles';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check-circle.svg';

type PropsType = {
  title: string;
  passwordPoint: boolean;
  className?: string;
  isTouched: boolean;
} & React.PropsWithChildren;

const CheckPasswordPoint: React.FC<PropsType> = (props) => {
  return (
    <StyledCheckPasswordPoint
      passwordPoint={props.passwordPoint}
      isTouched={props.isTouched}
      className={props?.className ?? ''}
    >
      <CheckCircleIcon className="styled-check-password-point__icon" />
      <p className="help-text">{props.title}</p>
    </StyledCheckPasswordPoint>
  );
};

export default CheckPasswordPoint;
