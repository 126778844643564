import React from 'react';
import { Box } from '@mui/material';
import Menu from '../../components/Menu';

type PropsType = {
  children?: JSX.Element;
};

const AuthorizedPage: React.FC<PropsType> = (props) => {
  return (
    <Box sx={{ display: { xs: 'block', md: 'flex' }, width: '100%', minHeight: '100vh' }}>
      <Menu />
      {props?.children}
    </Box>
  );
};

export default AuthorizedPage;
