import React from 'react';
import StyledCheckBox from './Checkbox.styles';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

type PropsType = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  checked: boolean;
  name?: string;

} & React.PropsWithChildren;

const Checkbox: React.FC<PropsType> = (props) => {
  return (
    <StyledCheckBox
      className={props?.className ?? ''}
      checked={props.checked}
    >
      {props.checked
        ? <CheckIcon className="styled-checkbox__icon" />
        : null
      }
      <input
        name={props?.name}
        type="checkbox"
        className="styled-checkbox__input"
        checked={props.checked}
        onChange={props?.onChange}
      />
    </StyledCheckBox>
  );
};

export default Checkbox;
