import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import StyledResetPassword from './ChangedPasswordPage.styles';

const ChangedPasswordPage = () => {
  const navigate = useNavigate();

  const handleNavToLoginPage = () => {
    navigate('/log-in');
  };

  return (
    <StyledResetPassword>
        <div className="styled-reset-page__content">
          <div className="styled-reset-page__title-area">
            <p className="website-header">Your password has been changed!</p>
            <p className="text-2 styled-reset-page__title-area__description">Use to Log In.</p>
          </div>
          <Button onClick={handleNavToLoginPage}>
            <h4>Go to Log In</h4>
          </Button>

        </div>
    </StyledResetPassword>
  );
};

export default ChangedPasswordPage;
