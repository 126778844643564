import styled from 'styled-components';

export default styled.header`
  position: relative;
  padding: 0px 0px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total-values {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .values-area {
      display: flex;
      column-gap: 20px;

      > div {
        border: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
        border-radius: ${({ theme }) => theme.borderRadius};
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 8px;

        .total-bulk-text {
          color: ${({ theme }) => theme.mainTheme.color.red.primary};
        }

        .pending-bulk-text {
          color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
        }
      }
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .main-info-user {
      display: flex;
      align-items: center;
      column-gap: 40px;

      .balance-area {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        > div {
          display: flex;
          align-items: center;
          column-gap: 8px;
          
          .icon {
            fill: ${({ theme }) => theme.mainTheme.color.typography.whiteText};
          }
        }

        .button-content {
          display: flex;
          column-gap: 8px;
          align-items: center;
          justify-content: center;

          h4 {
            font-size: 14px;
          }
        }

        .button-style {
          background: ${({ theme }) => theme.mainTheme.color.grey.dark};
          padding: 11px 20px;
        }
      }

      .avatar-area {
        cursor: pointer;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background: ${({ theme }) => theme.mainTheme.color.red.lightBackground};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .date-area {
      position: relative;
    }

    .date-picker-area {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 13px 20px;
      justify-content: space-between;
      border: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
      border-radius: ${({ theme }) => theme.borderRadius};

      .default-text {
        color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
      }
    }
  }
`;
