import React from 'react';
import ReactCodeInput from 'react-code-input';
import StyledCodeInput from './CodeInput.styles';

type PropsType = {
  onChange: (value: string) => void;
  className?: string;
  value: string;
  placeHolder?: string;
  nameInput: string;
  title?: string;
  error?: string;
};

const CodeInput: React.FC<PropsType> = (props) => {
  const currentError = props?.error || '';
  return (
    <StyledCodeInput
      error={currentError}
      name={props.nameInput}
      className={props?.className ?? ''}
    >
      <p className="label-text styled-code-input__label">{props?.title}</p>
      <ReactCodeInput
        value={props.value}
        placeholder={props?.placeHolder}
        className="styled-code-input__input text-4"
        name={props.nameInput}
        inputMode="numeric"
        fields={4}
        onChange={props.onChange}
        isValid={!!props?.error}
      />
      <div
        className="help-text styled-code-input__error-info"
        id={`${props.nameInput}__error`}
      >
        {props?.error}
      </div>

    </StyledCodeInput>
  );
};

export default CodeInput;
