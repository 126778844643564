import React, { useEffect, useMemo, useRef } from 'react';
import type { FirstDayOfWeek } from '@datepicker-react/hooks';
import { useMonth } from '@datepicker-react/hooks';
import Day from './Day';
import { ReactComponent as NextBtnIcon } from '../../assets/icons/header-next-btn.svg';
import { ReactComponent as PrevBtnIcon } from '../../assets/icons/header-prev-btn.svg';
import { ReactComponent as SuperNextBtnIcon } from '../../assets/icons/header-super-next-btn.svg';
import { ReactComponent as SuperPrevBtnIcon } from '../../assets/icons/header-super-prev-btn.svg';

const weekdayLabels = [
  'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',
];

type PropsType = {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
  goToPreviousMonthsByOneMonth: () => void;
  goToNextMonthsByOneMonth: () => void;
  goToPreviousYear: (numYears: number) => void;
  goToNextYear: (numYears: number) => void;
};

type CurrentDayType = {
  dayLabel: string;
  date: Date;
  isDisabled?: boolean;
};

const Month: React.FC<PropsType> = ({
  year,
  month,
  firstDayOfWeek,
  goToPreviousMonthsByOneMonth,
  goToNextMonthsByOneMonth,
  goToPreviousYear,
  goToNextYear,
}) => {
  const { monthLabel, days } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  const previousYear = useRef(year);
  const previousMonth = useRef(month - 1);

  const nextYear = useRef(year);
  const nextMonth = useRef(month + 1);

  useEffect(() => {
    if (month === 11) {
      ++nextYear.current;
      nextMonth.current = 0;
    }

    if (month === 0) {
      --previousYear.current;
      previousMonth.current = 11;
    }
  }, [month]);

  const previousCurrentMonth = useMonth({
    year: previousYear.current,
    month: previousMonth.current,
    firstDayOfWeek,
  });

  const nextCurrentMonth = useMonth({
    year: nextYear.current,
    month: nextMonth.current,
    firstDayOfWeek,
  });

  const currentCalendar = useMemo(() => {
    let currentMonth = days.filter((item) => item !== 0).map((item) => {
      let newItem: CurrentDayType = {
        dayLabel: '',
        date: new Date(),
        isDisabled: true,
      };
      if (typeof item === 'object') {
        newItem = { ...item, isDisabled: false };
      }
      return newItem;
    });

    const prevCount = days.lastIndexOf(0) + 1;

    const prevDays = previousCurrentMonth.days.splice(-prevCount, prevCount).map((item) => {
      let newItem: CurrentDayType = {
        dayLabel: '',
        date: new Date(),
        isDisabled: true,
      };
      if (typeof item === 'object') {
        newItem = { ...item, isDisabled: true };
      }
      return newItem;
    });

    const nextDays = nextCurrentMonth.days.filter((item) => item !== 0).map((item) => {
      let newItem: CurrentDayType = {
        dayLabel: '',
        date: new Date(),
        isDisabled: true,
      };
      if (typeof item === 'object') {
        newItem = { ...item, isDisabled: true };
      }
      return newItem;
    });

    currentMonth = prevDays.concat(currentMonth);

    currentMonth = currentMonth.concat(nextDays);

    currentMonth = currentMonth.splice(0, 42);
    return currentMonth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="styled-month__container">
      <div className="styled-month__container__title">
        <SuperPrevBtnIcon onClick={() => goToPreviousYear(1)} />
        <PrevBtnIcon onClick={goToPreviousMonthsByOneMonth} />
        <p className="text-4">{monthLabel}</p>
        <NextBtnIcon onClick={goToNextMonthsByOneMonth} />
        <SuperNextBtnIcon onClick={() => goToNextYear(1)} />
      </div>
      <div className="styled-month__container__main-content">
        <div className="styled-month__container__main-content__title">
          {weekdayLabels.length
            ? weekdayLabels.map((dayLabel) => (
              <div className="text-4 styled-month__container__main-content__title__day" key={dayLabel}>
                {dayLabel}
              </div>
            )) : null}
        </div>
        <div className="styled-month__container__main-content__area">
          {currentCalendar.length ? currentCalendar.map((day) => (
            typeof day === 'object' ? (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
                isDisabled={day.isDisabled}
              />
            ) : null
          )) : null}
        </div>
      </div>
    </div>
  );
};

export default Month;
