import React from 'react';
import { Button, Typography } from '@mui/material';
import { useAppDispatch } from '../../store/hooks';
import { ReactComponent as LogOutIcon } from '../../assets/icons/log-out.svg';
import { logout } from '../../store/user/userSlice';

function LogoutButton({ color }: { color: string }) {
  const dispatch = useAppDispatch();
  const onLogOut = () => {
    localStorage.clear();
    dispatch(logout());
  };

  return (
    <Button sx={{ textTransform: 'none' }} startIcon={<LogOutIcon />} onClick={onLogOut}>
      <Typography color={color} className="text-3">logout</Typography>
    </Button>
  );
}

export default LogoutButton;
