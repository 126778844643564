import styled from 'styled-components';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/900.css';
import '@fontsource/poppins/300.css';

export default styled.div`
  background: #FCF8F8;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .styled-app__loader {
    margin: auto;
    border: 20px solid ${({ theme }) => theme.mainTheme.color.grey.lightElements};
    border-radius: 50%;
    border-top: 20px solid ${({ theme }) => theme.mainTheme.color.red.primary};
    width: 150px;
    height: 150px;
    animation: spinner 4s linear infinite;

    @keyframes spinner {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    &-mini {
      margin: auto;
      border: 5px solid ${({ theme }) => theme.mainTheme.color.grey.lightElements};
      border-radius: 50%;
      border-top: 5px solid ${({ theme }) => theme.mainTheme.color.red.primary};
      width: 50px;
      height: 50px;
      animation: spinner 4s linear infinite;

      @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }

  .styled-app__container {
    width: 100%;
  }
`;
