import styled, { css } from 'styled-components';

export default styled.button<{ disabled?: boolean }>`
  font-family: ${({ theme }) => theme.font.family};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: #00000000;
  padding: 5px;
  border: none;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  ${({ theme, disabled }) => css`
    color: ${disabled ? theme.mainTheme.color.typography.greyText : theme.mainTheme.color.red.primary};
    fill: ${disabled ? theme.mainTheme.color.typography.greyText : theme.mainTheme.color.red.light};
    stroke: ${disabled ? theme.mainTheme.color.typography.greyText : theme.mainTheme.color.red.light};

    a {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${disabled ? theme.mainTheme.color.typography.greyText : theme.mainTheme.color.red.primary};
      fill: ${disabled ? theme.mainTheme.color.typography.greyText : theme.mainTheme.color.red.light};
    }

    :hover {
      color: ${!disabled ? theme.mainTheme.color.red.dark : theme.mainTheme.color.typography.greyText};
      fill: ${!disabled ? theme.mainTheme.color.red.dark : theme.mainTheme.color.typography.greyText};
      stroke: ${!disabled ? theme.mainTheme.color.red.dark : theme.mainTheme.color.typography.greyText};

      a {
        color: ${!disabled ? theme.mainTheme.color.red.dark : theme.mainTheme.color.typography.greyText};
        fill: ${!disabled ? theme.mainTheme.color.red.dark : theme.mainTheme.color.typography.greyText};
      }
    }
  `}

  > label {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  input {
    display: none;
  }

  > div {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: center;
  }

`;
