/* eslint-disable */
import loginFrame from '../../../../assets/icons/loginFrame.svg';
import GhostButton from '../../../GhostButton/GhostButton';
import { StyledLoginWebFrameContainer } from './WebFrame.styles';

type PropsType = {
  title: string;
  subTitle?: string;
  buttonText?: string;
};

const WebFrame: React.FC<PropsType> = (props) => {
  return (
    <StyledLoginWebFrameContainer>
      <img src={loginFrame} alt="Logo" className="login-frame" />

      <div className="positioned-ghost-button">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p className="log-in-text">{props.title}</p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p className="small-text">{props.subTitle}</p>
            <p className="small-text">
              <GhostButton>{props.buttonText}</GhostButton>
            </p>
          </div>
        </div>
      </div>
    </StyledLoginWebFrameContainer>
  );
};

export default WebFrame;
