import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import userThunk from '../../store/user/userThunk';
import config from '../../config';

const AffiliatePage = () => {
  const isLoading = useAppSelector((state) => state.userSlice.addition.isLoading);
  const authToken = useAppSelector((state) => state.userSlice.promoterAuth.accessToken);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchAuthToken = async () => {
      await dispatch(userThunk.getPromoterAuth()).unwrap();
    };
    if (!authToken) {
      fetchAuthToken();
    }
  }, [dispatch, authToken]);

  const loaderGif = 'https://i.pinimg.com/originals/59/22/20/5922208e18658f5e83b6ad801b895f71.gif';
  const iframeStyle = {
    backgroundImage:
      `url(${loaderGif})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  if (isLoading) {
    return (
      <Box width="100%">
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <div className="styled-app__loader-mini" />
        </Box>
      </Box>
    );
  }

  return (
    <Box width="100%">
      {
        authToken && (
          <Box
              sx={iframeStyle}
              component="iframe"
              height="100%"
              width="100%"
              frameBorder="0"
              allow="clipboard-write"
              src={`${config.firstPromoterUrl}/iframe?tk=${authToken}`}
          />
        )
      }
    </Box>
  );
};

export default AffiliatePage;
