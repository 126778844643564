/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { passwordCheckedPoints } from '../../utils/passwordCheckHelper';
import StyledInput from './Input.styles';
import { ReactComponent as EyeOpenedIcon } from '../../assets/icons/eye-opened.svg';
import { ReactComponent as EyeClosedIcon } from '../../assets/icons/eye-closed.svg';
import CheckPasswordArea from '../CheckPasswordArea';
import { checkIsPasswordErrorMessage } from '../../utils/errorCheckHelper';

type PropsType = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: string | number;
  placeHolder?: string;
  nameInput: string;
  title?: string;
  displayIcon?: boolean;
  type?: string;
  error?: string;
  isTouched?: boolean;
  password?: boolean;
  displayPasswordCounter?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input: React.FC<PropsType> = (props) => {
  const {
    onChange,
    className,
    value,
    placeHolder,
    nameInput,
    title,
    displayIcon,
    type,
    error,
    isTouched,
    password,
    displayPasswordCounter,
    ...inputProps
  } = props;
  const [isFocused, setIsFocused] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [togglePassword, setTogglePassword] = useState(!!password);
  const currentError = error || '';

  const onClickImg = () => {
    if (checkIsPasswordErrorMessage(nameInput) && displayIcon) {
      setTogglePassword(!togglePassword);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (props?.onBlur) {
      props?.onBlur(e);
    }
  };

  const checkScore = useMemo(() => {
    const score = zxcvbn(`${value}`).score;
    return score;
  }, [value]);

  return (
    <StyledInput
      error={currentError}
      name={nameInput}
      className={className ?? ''}
      isTouched={isTouched}
      isFirstRender={isFirstRender}
      displayIcon={displayIcon}
      displayPasswordCounter={displayPasswordCounter}
      checkScore={checkScore}
    >
      {title ? <p className="label-text styled-input__label">{title}</p> : null}
      <div className="styled-input__container">
        <input
          {...inputProps}
          type={togglePassword ? 'password' : type || 'text'}
          name={nameInput}
          className="styled-input__input text-4"
          id={nameInput}
          onChange={onChange}
          value={value}
          placeholder={placeHolder}
          autoComplete="on"
          onFocus={() => {
            setIsFocused(true);
            setIsFirstRender(false);
          }}
          onBlur={handleBlur}
        />
        {!!displayIcon &&
          (
            togglePassword
              ? <EyeClosedIcon onClick={onClickImg} className="styled-input__svg" />
              : <EyeOpenedIcon onClick={onClickImg} className="styled-input__svg" />
          )}
      </div>
      {
        displayPasswordCounter
          ? (
            <CheckPasswordArea
              checkScore={checkScore}
              passwordCheckedPoints={passwordCheckedPoints(props.value as string)}
              isFocused={isFocused}
              className="styled-input__special-color"
            />
          )
          : (
            error && isTouched ? (
              <div
                className="help-text styled-input__error-info"
                id={`${nameInput}__error`}
              >
                {!!isTouched && error}
              </div>
            ) : null
          )
      }

    </StyledInput>
  );
};

export default Input;
