import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Card, Box, Typography, Button } from '@mui/material';
import StyledProfileChangePasswordArea from './ProfileChangePasswordArea.styles';
import userThunks from '../../../../store/user/userThunk';
import { useAppDispatch } from '../../../../store/hooks';
import { resetPassByUserSchema } from '../../../../types/validationSchemes';
import Input from '../../../../components/Input/Input';
import { checkIsPasswordErrorMessage } from '../../../../utils/errorCheckHelper';

const ProfileChangePasswordArea = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { newPassword: '', repeatNewPassword: '', currentPassword: '' },
    validationSchema: resetPassByUserSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        await dispatch(userThunks.editPassword(values)).unwrap();
        toast.success('Password has been changed!');
      } catch (err) {
        if (checkIsPasswordErrorMessage(err)) {
          setErrors({ currentPassword: err });
        } else {
          toast.error(err);
        }
      }
    },
  });

  const inputArray = [
    {
      nameInput: 'currentPassword',
      title: 'Current Password',
      value: formik.values.currentPassword,
      error: formik.errors.currentPassword,
      isTouched: formik.touched.currentPassword,
    },
    {
      nameInput: 'newPassword',
      title: 'New Password',
      value: formik.values.newPassword,
      error: formik.errors.newPassword,
      isTouched: formik.touched.newPassword,
    },
    {
      nameInput: 'repeatNewPassword',
      title: 'Repeat New Password',
      value: formik.values.repeatNewPassword,
      error: formik.errors.repeatNewPassword,
      isTouched: formik.touched.repeatNewPassword,
    },
  ];

  const styles = {
    card: { p: 5, borderRadius: 5 },
    container: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: { xs: 'unset', lg: '1fr 3fr' },
    },
    text: {
      py: 2,
      fontSize: '24px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    button: {
      px: 6,
      py: 1.5,
      borderRadius: 2,
      textTransform: 'capatilize',
    },
  };

  return (
    <StyledProfileChangePasswordArea>
      <Card sx={styles.card} component="form" onSubmit={formik.handleSubmit}>
        <Box sx={styles.container} className="styled-profile-change-info__section">
          <Typography component="p" sx={styles.text}>Change Password</Typography>
          <div>
            <div className="styled-profile-change-info__section__input-area">
              {
                inputArray.map((item, index) => (
                  <Input
                    key={index}
                    nameInput={item.nameInput}
                    placeHolder={item.title}
                    title={item.title}
                    onChange={formik.handleChange}
                    value={item.value}
                    error={item.error}
                    isTouched={item.isTouched}
                    onBlur={formik.handleBlur}
                    password
                    displayIcon
                  />
                ))
              }
            </div>
          </div>
        </Box>
        <div className="styled-profile-change-info__section__button-area">
          <Button sx={styles.button} type="submit" variant="contained">
            Change password
          </Button>
        </div>
      </Card>
    </StyledProfileChangePasswordArea>
  );
};

export default ProfileChangePasswordArea;
