import React from 'react';
import InputMask from 'react-input-mask';
import StyledInput from './PhoneInput.styles';

type PropsType = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: string;
  placeHolder?: string;
  nameInput: string;
  title?: string;
  error?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const PhoneInput: React.FC<PropsType> = (props) => {
  const {
    onChange,
    className,
    value,
    placeHolder,
    nameInput,
    title,
    error,
  } = props;
  const currentError = error || '';

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props?.onBlur) {
      props?.onBlur(e);
    }
  };

  return (
    <StyledInput
      error={currentError}
      name={nameInput}
      className={className ?? ''}
    >
      <p className="label-text styled-input__label">{title}</p>
      <div className="styled-input__container">
        <InputMask
          mask="+1 999 999 9999"
          maskChar=" "
          name={nameInput}
          className="styled-input__input text-4"
          id={nameInput}
          onChange={onChange}
          value={value}
          placeholder={placeHolder}
          autoComplete="on"
          onBlur={handleBlur}
        />
      </div>
      <div
        className="help-text styled-input__error-info"
        id={`${nameInput}__error`}
      >
        {error}
      </div>
    </StyledInput>
  );
};

export default PhoneInput;
