import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import StyledDashBoardMainContent from './TableArea.styles';
import Table from '../Table';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Pagination from '../Pagination';
import { changeFilter } from '../../store/order/orderSlice';
import orderThunk from '../../store/order/orderThunk';
import { orderTableHead } from '../../store/order/orderTableHead';

const TableArea = () => {
  const orderSlice = useAppSelector((state) => state.orderSlice);
  const dispatch = useAppDispatch();
  const currentCount = orderSlice?.options?.perPage || 7;
  const currentTotalCountItems = orderSlice?.options?.totalRecords || 0;
  const onChangeFilter = (title: string, value: string | Date | null) => {
    dispatch(changeFilter({ title, value }));
  };

  const handleChangePage = (page: number) => {
    dispatch(changeFilter({ title: 'page', value: `${page}` }));
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(orderThunk.getOrders(orderSlice.filter)).unwrap();
      } catch (error) {
        toast.error(error);
      }
    })();
  }, [dispatch, orderSlice.filter]);

  return (
    <StyledDashBoardMainContent>
      {
        orderSlice.addition.isLoading ? (
          <div className="styled-app__loader-mini" />
        ) : (
          <>
            <Table
              filter={orderSlice.filter}
              setFilter={onChangeFilter}
              mainArray={orderSlice.orders}
              tableHead={orderTableHead}
            />
            {
              orderSlice?.options ? (
                <Pagination
                  currentPage={orderSlice.options.page}
                  totalCount={orderSlice.options.totalPages}
                  pageSize={currentCount}
                  onPageChange={handleChangePage}
                  countItems={currentTotalCountItems}
                />
              ) : null
            }
          </>
        )
      }
    </StyledDashBoardMainContent>
  );
};

export default TableArea;
