import styled from 'styled-components';

export default styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
`;
