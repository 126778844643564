import styled from 'styled-components';

export default styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 12%;
  margin-top: -12%;

  @media (max-width: 1400px) {
    max-width: 440px;
  }
  @media (max-width: 1350px) {
    max-width: 410px;
  }
  @media (max-width: 1200px) {
    max-width: 360px;
  }
  @media (max-width: 1100px) {
    max-width: 330px;
  }
  @media (max-width: 1000px) {
    max-width: 300px;
  }
  @media (max-width: 900px) {
    max-width: 270px;
  }
  @media (max-width: 768px) {
    max-width: 500px;
    margin-top: 12%;
  }

  .styled-login-page__content {
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 26px;
    border-radius: 20px;
    background: ${({ theme }) => theme.mainTheme.color.background};
  }

  .styled-login-page__title-area {
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    text-align: center;

    &__title {
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: uppercase;
    }

    &__description {
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.45px;
      color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    }
  }

  .styled-login-page__main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    align-items: center;
  }

  .styled-login-page__inputs-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`;
