import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import StyledPaymentFinalOrder from './PaymentFinalOrder.styles';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import UploadingBulkProgress from '../../../../components/UploadingBulkProgress/UploadingBulkProgress';
import { PaymentPointsArray } from '../../../../routes';
import { useAppSelector } from '../../../../store/hooks';

type PropsType = {
  className?: string;
} & React.PropsWithChildren;

const PaymentFinalOrder: React.FC<PropsType> = (props) => {
  const amount = useAppSelector((state) => state.userSlice.amountPayment);
  const currentTotalPrice = amount.toFixed(2);
  const navigate = useNavigate();

  const onClickPayments = () => {
    navigate('/payments');
  };

  return (
    <StyledPaymentFinalOrder className={props?.className ?? ''}>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <UploadingBulkProgress pointsArray={PaymentPointsArray} />
      </Box>
      <div className="styled-final-order__container">
        <div className="styled-final-order__container__title">
          <h3>Success</h3>
          <p className="text-2">Your payment was successful. All details about your order can be found below.</p>
        </div>
        <div className="styled-final-order__container__info-section">
          <p className="text-1">Total:</p>
          <h3 className="styled-final-order__container__total-price">${currentTotalPrice}</h3>
        </div>
        <div>
          <Button
            onClick={onClickPayments}
            startIcon={<ArrowLeftIcon fill="white" />}
            sx={{ borderRadius: 2, py: 1.5, px: 4 }}
            type="submit"
            variant="contained"
          >
            <h4>Back to Payments</h4>
          </Button>
        </div>
      </div>
    </StyledPaymentFinalOrder>
  );
};

export default PaymentFinalOrder;
