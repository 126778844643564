import styled, { css } from 'styled-components';

export default styled.label<{ activeMonthsLength: number }>`
  .styled-date-picker__container {
    display: grid;
    grid-template-columns: repeat(${({ activeMonthsLength }) => activeMonthsLength}, auto);
    grid-gap: 0 4px;
  }

  .styled-month__container {
    ${({ theme }) => css`
      box-shadow: ${theme.mainTheme.shadow.extraLarge};
      border-radius: ${theme.borderRadius};
      background-color: ${theme.mainTheme.color.background};
    `}

    &__main-content {
      padding: 8px 12px;

      &__title {
        display: grid;
        grid-template-columns: repeat(7, auto);
        justify-content: space-between;
        margin-bottom: 10px;

        &__day {
          width: 30px;
          text-align: center;
        }
      }

      &__area {
        display: grid;
        grid-template-columns: repeat(7, auto);
        justify-content: center;
      }
    }
  }

  .styled-month__container__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    fill: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.lightBackground};
    border-radius: 5px 5px 0px 0px;
    padding: 4px 8px;

    svg {
      cursor: pointer;
    }
  }

  .default-background-day {
    background: #00000000;
  }

  .selected-background-day {
    background: ${({ theme }) => theme.mainTheme.color.grey.background};
  }

`;

export const StyledDay = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 3px 6px;

  button {
    ${({ isDisabled }) => (!isDisabled ? 'cursor: pointer' : 'pointer-events: none')};
    border: 0;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    text-align: center;
    vertical-align: middle;
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};

    :hover {
      ${({ theme, isDisabled }) => !isDisabled && `border: 1px solid ${theme.mainTheme.color.grey.dark}`};
    }
  }

  .normal-color, .selected-color {
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    background: #00000000;
  }

  .selected-first-or-last-color {
    ${({ theme }) => css`
      color: ${theme.mainTheme.color.typography.whiteText};
      background: ${theme.mainTheme.color.grey.dark};
    `}
  }

  .disabled-color {
    color: ${({ theme }) => theme.mainTheme.color.grey.lightElements};
    background: #00000000;
  }

`;
