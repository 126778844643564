import styled from 'styled-components';

export default styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
  }

  .styled-profile-change-info__section {

    > div {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &__input-area {
      display: flex;
      gap: 24px;
      flex-direction: row;

      @media (max-width: 650px) {
        flex-direction: column;
      }
    }

    &__button-area {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
