import React, { useMemo } from 'react';
import CheckPasswordPoint from '../CheckPasswordPoint';
import StyledCheckPasswordPoint from './CheckPasswordArea.styles';

type PropsType = {
  checkScore: number;
  passwordCheckedPoints: Array<boolean>;
  isFocused: boolean;
  className?: string;
} & React.PropsWithChildren;

const pointsArray = ['8 symbols', '1 letter', '1 number'];

const CheckPasswordArea: React.FC<PropsType> = (props) => {
  const ArrayCheckScorePoints = useMemo(() => {
    const array = ['', '', '', '', ''];
    return array.map((_elem, index) => {
      return index <= props.checkScore ? props?.className : 'styled-input__default-color';
    });
  }, [props.checkScore, props?.className]);

  return (
    <StyledCheckPasswordPoint
    >
      <div className="styled-check-password-point__line">
        {ArrayCheckScorePoints.map((elem, index) => (
          <div key={index} className={elem} />
        ))}
      </div>
      <p className="help-text">Password must contain at least:</p>
      <div className="styled-check-password-point__info-area">
        {pointsArray.map((item, index) => (
          <CheckPasswordPoint
            key={index}
            title={item}
            passwordPoint={props.passwordCheckedPoints[index]}
            isTouched={props.isFocused}
          />
        ))}
      </div>
    </StyledCheckPasswordPoint>
  );
};

export default CheckPasswordArea;
