import ReactGA from 'react-ga4';
import type { UaEventOptions } from 'react-ga4/types/ga4';
import config from '../config';

const Events = {
  signup: {
    category: 'User',
    action: 'Sign Up',
    label: 'Created an Account',
  },
  subscription: {
    category: 'User',
    action: 'Subscription',
    label: 'Active a Plan',
  },
};

const ReactGAEvent = (params: UaEventOptions) => {
  try {
    if (config.googleAnalytics.enabled) {
      ReactGA.event(params);
    }
  } catch (error) {
    console.error('Failed to send analytics event', params, error);
  }
};

const signupEvent = () => {
  ReactGAEvent(Events.signup);
};

const subscriptionEvent = () => {
  ReactGAEvent(Events.subscription);
};

export default {
  signupEvent,
  subscriptionEvent,
};
