import styled from 'styled-components';

export default styled.tr`
  td {
    color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
    align-items: center;
    text-align: center;
    padding: 13px 5px;
    border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
    font-size: 15px;
    font-weight: 300;
  }

  tr {
    td {
      > div {
        display: flex;
        justify-content: center;
      }
    }
    
    :last-child {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};

        :first-child {
          border-radius: 0 0 0 4px;
        }

        :last-child {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }

  .styled-table-row__text {
    width: 100%;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .order-status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    border-radius: 5px;
  }

  .uploaded-status {
    background-color: ${({ theme }) => theme.mainTheme.color.yellow};
  }

  .processing-status {
    background-color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
  }

  .successful-status {
    background-color: ${({ theme }) => theme.mainTheme.color.green};
  }

  .not-found-status {
    background-color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
  }

`;
