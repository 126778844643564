import React, { useEffect, useState } from 'react';
import StyledDropDownList from './DropDownList.styles';
import type { FilterType } from '../../../../types/filterTypes';
import DatePicker from '../../../../components/DatePicker';
import { useAppSelector } from '../../../../store/hooks';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
  onCloseDropDownList: () => void;
};

const DropDownList: React.FC<PropsType> = (props) => {
  const currentDateSelectArray = useAppSelector((state) => state.orderSlice.datesSelect);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  useEffect(() => {
    if (props.filter.endDate) {
      if (isOpenDatePicker) {
        setIsOpenDatePicker(false);
        props.onCloseDropDownList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.endDate]);

  const handleSetDate = (start: Date | null, end: Date | null) => {
    props.setFilter('startDate', start);
    props.setFilter('endDate', end);
    props.onCloseDropDownList();

    if (isOpenDatePicker) setIsOpenDatePicker(false);
  };

  const handleClick = (index: number, startDate: Date | null, endDate: Date | null) => {
    return index !== currentDateSelectArray.length - 1
      ? handleSetDate(startDate, endDate)
      : setIsOpenDatePicker(true);
  };
  return (
    <StyledDropDownList isOpenDatePicker={isOpenDatePicker}>
      <div className="styled-list">
        {!isOpenDatePicker && currentDateSelectArray.length
          ? currentDateSelectArray.map((item, index) => (
            <p
              key={index}
              className="text-4"
              onClick={() => handleClick(index, item.startDateRange, item.endDateRange)}
            >
              {item.title}
            </p>
          ))
          : null}
      </div>
      {isOpenDatePicker ? (
        <DatePicker filter={props.filter} setFilter={props.setFilter} />
      ) : null}
    </StyledDropDownList>
  );
};

export default DropDownList;
