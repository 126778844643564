import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
  padding: 8px 0 24px 0;

  .styled-check-password-point__line {
    display: flex;
    width: 100%;
    column-gap: 8px;
    padding-bottom: 8px;

    div {
      width: 100%;
      height: 8px;
      border-radius: 16px;
    }
  }

  .styled-check-password-point__info-area {
    padding-top: 4px;
    display: flex;
    column-gap: 8px;
  }
`;
