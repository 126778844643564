import React from 'react';
import ProfileMainContent from './components/ProfileMainContent/ProfileMainContent';
import StyledProfilePage from './ProfilePage.styles';

const ProfilePage = () => {
  return (
    <StyledProfilePage>
      <ProfileMainContent />
    </StyledProfilePage>
  );
};

export default ProfilePage;
