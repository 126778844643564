import styled from 'styled-components';

export default styled.tbody`
  td {
    color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
    align-items: center;
    text-align: center;
    padding: 13px 5px;
    border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
  }

  tr {
    td {
      > div {
        display: flex;
        justify-content: center;
      }
    }
    
    :last-child {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};

        :first-child {
          border-radius: 0 0 0 4px;
        }

        :last-child {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }

  .styled-table-body__one-column-td {
    border-radius: 0 0 4px 4px !important;

    &__no-data-info {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

`;
