import styled, { css } from 'styled-components';

export default styled.div<{ passwordPoint: boolean; isTouched: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-align: center;
  column-gap: 4px;

  .styled-check-password-point__icon {
    color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    ${({ theme, isTouched }) => {
    if (isTouched) {
      return css`
        color: ${theme.mainTheme.color.typography.lightGreyText};
        `;
    }
  }}
    ${({ theme, passwordPoint, isTouched }) => {
    if (passwordPoint) {
      return css`
        color: ${theme.mainTheme.color.green};
        `;
    }
    if (!passwordPoint && !isTouched) {
      return css`
      color: ${theme.mainTheme.color.red.bright};
      `;
    }
  }}
  }
`;
