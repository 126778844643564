import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 60px;

  .styled-amount__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    &__button {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  .styled-amount__container {
    width: 100%;
    max-width: 400px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
    border-radius: 20px;
    background: ${({ theme }) => theme.mainTheme.color.background};

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      row-gap: 16px;

      h3 {
        color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
      }

      .text-2 {
        color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      }
    }

    &__main-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      > p {
        color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      }

      &__section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
      }

      &__total-price {
        color: ${({ theme }) => theme.mainTheme.color.red.primary};
      }
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: start;
      row-gap: 48px;
    }
  }
`;
