import styled, { css } from 'styled-components';

export default styled.div<{ error?: string; name: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .styled-code-input__label {
    color: ${({ theme }) => theme.mainTheme.color.grey.dark};
  }

  .styled-code-input__input {
    position: relative;
    display: flex !important;
    align-items: center;
    width: 100%;
    max-width: 340px;
    column-gap: 8px;
    input {
      text-align: center;
      outline: none;
      width: 100%;
      height: 48px;
      ${({ theme }) => css`
        background: ${theme.mainTheme.color.background};
        border-radius: ${theme.borderRadius};
        border: 1px solid ${theme.mainTheme.color.grey.background};
        color: ${theme.mainTheme.color.typography.lightGreyText};
      `}

      :focus {
        border: 1px solid ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
      }
      :valid {
        color: ${({ theme }) => theme.mainTheme.color.grey.dark};
      }
    }
    ${({ theme, error, name }) => {
    if (error) {
      return css`
        input {
          border: 1px solid ${theme.mainTheme.color.red.bright};
          :valid ~ .styled-input__label{
            color: ${theme.mainTheme.color.red.bright};
          }
        }
        #${name}__error{
          color: ${theme.mainTheme.color.red.bright};
        }
      `;
    }
  }}
  }

  .styled-code-input__error-info {
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    align-items: center;
  }
  ${({ theme, error, name }) => {
    if (error) {
      return css`
        #${name}{
          border: 1px solid ${theme.mainTheme.color.red.bright};
          :valid ~ .styled-code-input__label{
            color: ${theme.mainTheme.color.red.bright};
          }
        }
        #${name}__error{
          color: ${theme.mainTheme.color.red.bright};
        }
      `;
    }
  }}
    `;
