import { Link, useNavigate } from 'react-router-dom';
import { Link as MUILink, Button, Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import userThunks from '../../store/user/userThunk';
import Input from '../../components/Input';
import StyledLogInPage from './LogInPage.styles';
import { useAppDispatch } from '../../store/hooks';
import { loginSchema } from '../../types/validationSchemes';
import { checkIsEmailErrorMessage, checkIsPasswordErrorMessage } from '../../utils/errorCheckHelper';
import Frame from '../../components/Frame';

const LogInPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: loginSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        await dispatch(userThunks.loginByPassEmail(values)).unwrap();
        await dispatch(userThunks.getCustomer()).unwrap();
        const path = '/dashboard';
        navigate(path);
      } catch (err) {
        if (checkIsPasswordErrorMessage(err)) {
          setErrors({ password: err });
        } else if (checkIsEmailErrorMessage(err)) {
          setErrors({ email: err });
        } else {
          console.error(err);
        }
      }
    },
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Frame
          title="LOG IN"
          subTitle="Don't have an account yet?"
          buttonText="Sign Up"
        />
      <StyledLogInPage>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Box className="styled-login-page__content">
            <Box className="styled-login-page__title-area">
              <Typography className="styled-login-page__title-area__title">Welcome back</Typography>
              <Typography className="text-2 styled-login-page__title-area__description">Log In to continue.</Typography>
            </Box>
            <Box className="styled-login-page__main-content">
              <Box className="styled-login-page__inputs-area">
                <Input
                  nameInput="email"
                  placeHolder="Email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.errors.email}
                  isTouched={formik.touched.email}
                  onBlur={formik.handleBlur}
                />
                <Input
                  nameInput="password"
                  placeHolder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.errors.password}
                  isTouched={formik.touched.password}
                  onBlur={formik.handleBlur}
                  password
                  displayIcon
                />
              </Box>
              <MUILink component={Link} underline="hover" to="/reset-password">
                <Typography variant="body2">Forgot Password?</Typography>
              </MUILink>
            </Box>
            <Button sx={{ py: 2, borderRadius: 2, mb: 2 }} type="submit" variant="contained" fullWidth>
              Log in
            </Button>
          </Box>
        </Box>
      </StyledLogInPage>
    </Box>
  );
};

export default LogInPage;
