import styled from 'styled-components';

export default styled.div`
  width: 100%;
  padding: 25px 30px;
  
  .styled-signup-page__container__check-area {
    display: inline-flex;
  }

`;
