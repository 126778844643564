import styled, { css } from 'styled-components';

export default styled.div<{ error?: string; name: string; isTouched?: boolean; displayIcon?: boolean; displayPasswordCounter?: boolean; checkScore: number; isFirstRender: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .styled-input__container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .styled-input__svg {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: auto;
    stroke: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
  }

  .styled-input__label {
    color: ${({ theme }) => theme.mainTheme.color.grey.dark};
  }

  .styled-input__input {
    ${({ theme, displayIcon }) => css`
      font-family: ${theme.font.family};
      background: ${theme.mainTheme.color.grey.lightBackground};
      border-radius: ${theme.borderRadius};
      outline: none;
      border: 1px solid ${theme.mainTheme.color.grey.background};
      color: ${theme.mainTheme.color.grey.dark};
      width: 100%;
      height: 48px;
      padding:0 14px 0 20px;
      ${() => {
    if (displayIcon) {
      return css`
            padding-right: 44px;
            `;
    }
  }}
      :focus {
        border: 1px solid ${theme.mainTheme.color.typography.lightGreyText};
      }

      ::placeholder {
        color: ${theme.mainTheme.color.typography.lightGreyText};
      }
    `}
  }

  input[type = "password"] {
    letter-spacing: 5px;

    ::placeholder {
      letter-spacing: 0;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
      appearance: none;
      -moz-appearance: textfield;
  }

  input {
    :disabled {
      color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    }
  }

  .styled-input__special-color {
    ${({ theme, isFirstRender, displayPasswordCounter, checkScore }) => {
    if (!isFirstRender && displayPasswordCounter) {
      if (checkScore < 1) {
        return css`
          background: ${theme.mainTheme.color.red.bright};
          `;
      }
      if (checkScore < 3) {
        return css`
          background: ${theme.mainTheme.color.yellow};
          `;
      }
      if (checkScore < 5) {
        return css`
          background: ${theme.mainTheme.color.green};
          `;
      }
    }
    return css`
      background: ${theme.mainTheme.color.grey.lightElements};
    `;
  }}
  }

  .styled-input__default-color {
    background-color: ${({ theme }) => theme.mainTheme.color.grey.lightElements};
  }

  .styled-input__error-info {
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    align-items: center;
  }

  ${({ theme, error, isTouched, name, displayPasswordCounter, isFirstRender, checkScore }) => {
    if (error && isTouched) {
      return css`
        #${name}{
          border: 1px solid ${theme.mainTheme.color.red.bright};
          :valid ~ .styled-input__label{
            color: ${theme.mainTheme.color.red.bright};
          }
        }
        #${name}__error{
          color: ${theme.mainTheme.color.red.bright};
        }
      `;
    }
    if (displayPasswordCounter && !isFirstRender) {
      if (checkScore < 1) {
        return css`
            #${name}{
        border: 1px solid ${theme.mainTheme.color.red.bright};
            }
        `;
      }
      if (checkScore < 3) {
        return css`
            #${name}{
        border: 1px solid ${theme.mainTheme.color.yellow};
            }
        `;
      }
      if (checkScore < 5) {
        return css`
            #${name}{
        border: 1px solid ${theme.mainTheme.color.green};
            }
        `;
      }
    }
  }}
    `;
