import styled from 'styled-components';

export default styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};

  .styled-uploading-bulk-progress__point {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__line {
      margin-top: -20px;
      width: 130px;
      height: 2px;
      background: ${({ theme }) => theme.mainTheme.color.grey.lightBackground};
    }
    @media (max-width: 600px) {
      width: 130px;
    }
  }
`;
