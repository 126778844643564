import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: ${({ theme }) => theme.mainTheme.color.background};
  font-family: ${({ theme }) => theme.font.family};
  font-style: normal;
  color: ${({ theme }) => theme.mainTheme.color.typography.blackText};

  pre {
    font-family: ${({ theme }) => theme.font.family};
  }

  div {
    box-sizing: border-box;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  .skip-table {
    border: 1px solid;
    border-collapse: collapse;
  }
  
  .skip-column {
    word-break: break-all;
  }

  .text-1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
  }

  .text-2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }

  .text-3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
  }

  .text-4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  }

  .button-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }

  .label-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
  }

  .help-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
  }

  .website-header {
    font-weight: 700;
    font-size: 36px;
    line-height: 110%;
    text-transform: uppercase;
  }
}
`;
