import React from 'react';
import clsx from 'clsx';
import StyledPagination from './Pagination.styles';
import { DOTS, usePagination } from './usePagination';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';

type PropsType = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  countItems: number;
  className?: string;
} & React.PropsWithChildren;

const Pagination: React.FC<PropsType> = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    countItems,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const isDisplayPagination = +currentPage === 0 || paginationRange!.length < 2 || countItems === 0;

  if (isDisplayPagination) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange![paginationRange!.length - 1];

  const maxPageSize = pageSize * currentPage;

  const currentMaxOrderNumber = maxPageSize > countItems ? countItems : maxPageSize;
  const currentMinOrderNumber = maxPageSize - (pageSize - 1);
  const isFirstPage = currentPage === 1;
  const isSelectedPage = (page: number) => {
    return page === currentPage;
  };
  const isLastPage = currentPage === lastPage;

  return (
    <StyledPagination className={className ?? ''}>
      <div className="text-2">Showing {currentMinOrderNumber} to {currentMaxOrderNumber} of {countItems} entries</div>
      <div>
        <ul
          className="pagination-container"
        >
          <li
            className={clsx(['pagination-item', 'chevron', { disabled: isFirstPage }])}
            onClick={onPrevious}
          >
            <ChevronLeftIcon className="pagination-container__icon" />
          </li>
          {paginationRange?.length ? paginationRange!.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return <li key={index} className="pagination-item dots">...</li>;
            }

            return (
              <li
                key={index}
                className={clsx(['pagination-item', 'chevron', { selected: isSelectedPage(pageNumber) }])}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          }) : null}
          <li
            className={clsx(['pagination-item', 'chevron', { disabled: isLastPage }])}
            onClick={onNext}
          >
            <ChevronRightIcon className="pagination-container__icon" />
          </li>
        </ul>
      </div>
    </StyledPagination>
  );
};

export default Pagination;
