import { useNavigate } from 'react-router-dom';
import { Card, Typography, Box, Button } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';

const SubscriptionArea = () => {
  const userSubscription = useAppSelector((state) => state.userSlice.user.subscription);
  const navigate = useNavigate();
  if (!userSubscription) {
    return null;
  }
  return (
    <Card sx={{ p: 5, borderRadius: 5 }}>
      <Typography
        component="p"
        sx={{
          py: 2,
          fontSize: '24px',
          fontWeight: 700,
          textTransform: 'capitalize',
        }}
      >
      Subscription Info
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, alignItems: 'flex-end' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '40%', gap: 2 }}>
        <Box>
          <Typography
            component="p"
            sx={{
              fontSize: '15px',
              fontWeight: 300,
              textTransform: 'capitalize',
            }}
          >
          Type
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: '15px',
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
          >
          {userSubscription.type}
          </Typography>
        </Box>
        <Box>
          <Typography
            component="p"
            sx={{
              fontSize: '15px',
              fontWeight: 300,
              textTransform: 'capitalize',
            }}
          >
          Status
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: '15px',
              fontWeight: 700,
              textTransform: 'capitalize',
            }}
          >
          {userSubscription.status}
          </Typography>
        </Box>
        </Box>
        <Box>
          <Button
            onClick={() => navigate('/subscription')}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'capatilize',
            }}
            variant="contained"
          >
            Update Subscription
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default SubscriptionArea;
