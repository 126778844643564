import React from 'react';
import Button from '../Button';
import GhostButton from '../GhostButton';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import Modal from '../Modal';

type PropsType = {
  onClick?: () => void;
  className?: string;
  onCloseModal?: () => void;

} & React.PropsWithChildren;

const ErrorBalanceModal: React.FC<PropsType> = (props) => {
  return (
    <Modal>
      <h3 className="styled-modal__container__text">Not enough balance</h3>
      <h1 className="styled-modal__container__text">Add your account balance and complete the Bulk Skip trace</h1>
      <div className="styled-modal__container__buttons-area">
        <GhostButton onClick={props?.onCloseModal}>
          <p className="text-3">Cancel</p>
        </GhostButton>
        <Button onClick={props?.onClick}>
            <h4>Add balance</h4>
            <PlusIcon />
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorBalanceModal;
