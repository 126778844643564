/* eslint-disable */
import React from 'react';

import GhostButton from '../../../GhostButton/GhostButton';
import facebook from '../../../../assets/icons/facebook.svg';
import twitter from '../../../../assets/icons/twitter 1.svg';
import instagram from '../../../../assets/icons/instagram.svg';
import { StyledMobileImageContainer } from './MobileFrame.styles';

type PropsType = {
  title: string;
  subTitle?: string;
  buttonText?: string;
};
const MobileFrame: React.FC<PropsType> = (props) => {
  return (
    <StyledMobileImageContainer>
      <div className="inner-container">
        <div className="text-container">
          <div>
            <p className="title-text">{props.title}</p>
          </div>
          <div className="sub-text-container">
            <p className="sub-text">{props.subTitle}</p>
            <GhostButton>{props.buttonText}</GhostButton>
          </div>
        </div>
        <div className="social-media-container">
          <img src={facebook} alt="Facebook" />
          <img src={instagram} alt="Instagram" />
          <img src={twitter} alt="Twitter" />
        </div>
      </div>
    </StyledMobileImageContainer>
  );
};

export default MobileFrame;
