import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import StyledApp from './App.styles';
import RouterPages from './pages/RouterPages/RouterPages';

import { useAppDispatch } from './store/hooks';
import userThunks from './store/user/userThunk';
import useGoogleAnalytics from './utils/useGoogleAnalytics';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const initialized = useGoogleAnalytics();
  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location, initialized]);
  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await dispatch(userThunks.loginByToken()).unwrap();
          await dispatch(userThunks.getCustomer()).unwrap();
          await dispatch(userThunks.getCards()).unwrap();
        } catch (err) {
          console.error(err);
        }
      }
      setIsLoading(false);
    })();
  }, [dispatch]);

  if (isLoading) {
    return (
      <StyledApp>
        <div className="styled-app__loader" />
      </StyledApp>
    );
  }
  return (
    <StyledApp>
      <div className="styled-app__container">
        <RouterPages />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </StyledApp>
  );
};

export default App;
