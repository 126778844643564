import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as StyleThemeProvidor } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import store from './store/store';
import App from './App';
import { GlobalStyle } from './styles/GlobalStyles';
import { theme, defaultTheme } from './styles/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Router>
    <MUIThemeProvider theme={theme}>
      <StyleThemeProvidor theme={defaultTheme}>
        <GlobalStyle />
        <Provider store={store}>
          <App />
        </Provider>
      </StyleThemeProvidor>
    </MUIThemeProvider>
  </Router>,
);
