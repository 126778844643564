export const codeAdminTableHead = [
  {
    title: '#',
    name: 'couponId',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Discount Code',
    name: 'code',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'One Claim Per User',
    name: 'oneClaimPerUser',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Discount',
    name: 'discount',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Expires',
    name: 'expire_at',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Action',
    name: 'action',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
];
