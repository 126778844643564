import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .styled-select__container {
    padding: 12px 34px;

    &__default-point {
      box-sizing: border-box;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      ${({ theme }) => css`
        background: ${theme.mainTheme.color.grey.dark};
        border: 7px solid ${theme.mainTheme.color.grey.lightBackground};
      `}
    }

    &__current-point {
      ${({ theme }) => css`
        background: ${theme.mainTheme.color.background};
        border: 7px solid ${theme.mainTheme.color.red.bright};
        outline: 6px solid ${theme.mainTheme.color.grey.lightBackground};
      `}
    }
  }

  .styled-select__icon-container {
    padding: 4px 28px;

    &__icon {
      border-radius: 50%;
      padding: 4px;
      ${({ theme }) => css`
        stroke: ${theme.mainTheme.color.red.primary};
        background: ${theme.mainTheme.color.grey.lightBackground};
      `}
    }
  }

  .done {
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
  }

  .primary {
    color: ${({ theme }) => theme.mainTheme.color.red.primary};
  }
`;
