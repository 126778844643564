import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';
import { Box, Card, Typography, Button } from '@mui/material';
import ChangeAvatarArea from '../../../../components/ChangeAvatarArea/ChangeAvatarArea';
import { convertFileToBase64 } from '../../../../utils/convertFileHelper';
import StyledProfileChangeInfoArea from './ProfileChangeInfoArea.styles';
import userThunks from '../../../../store/user/userThunk';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { userSchema } from '../../../../types/validationSchemes';
import Input from '../../../../components/Input/Input';
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';
import { checkIsCouponErrorMessage } from '../../../../utils/errorCheckHelper';
import config from '../../../../config';
import { paymentApi } from '../../../../api/services/userApi';

const promoCodePlans = config.promoCodePlans as Record<string, { id: string }>;

const ProfileChangeInfoArea = () => {
  const userSlice = useAppSelector((state) => state.userSlice.user);
  const avatarSrcRef = useRef(userSlice.avatar || '');
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: userSlice.firstName || '',
      removeAvatar: false,
      lastName: userSlice.lastName || '',
      address: userSlice.address || '',
      phone: userSlice.phone || '',
      city: userSlice.city || '',
      state: userSlice.state || '',
      zipCode: userSlice.zipCode || '',
      code: userSlice.code || '',
      file: undefined,
    },
    validationSchema: userSchema,
    isInitialValid: false,
    onSubmit: async (values, { setErrors }) => {
      const promoPlan = promoCodePlans[values.code];
      const userInfo = { ...values, code: promoPlan ? '' : values.code };
      try {
        await dispatch(userThunks.changeInfoUser(userInfo)).unwrap();
        toast.success('Changes have been saved!');
        if (promoPlan?.id) {
          await paymentApi.deleteSubscription();
          const res = await paymentApi.checkoutSubscription(
            promoPlan.id,
          );
          if (res.status === StatusCodes.OK) {
            toast.success('Promo code applied successfully!');
            const link = document.createElement('a');
            link.href = res.data.data?.session.url || '';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      } catch (err) {
        if (checkIsCouponErrorMessage(err)) {
          setErrors({ code: err });
        } else {
          toast.error(err);
        }
      }
    },
  });

  const handleChangeAvatar = async (file: File) => {
    avatarSrcRef.current = URL.createObjectURL(file);
    const avatarFile = await convertFileToBase64(file);
    formik.setFieldValue('file', avatarFile);
    formik.setFieldValue('removeAvatar', false);
  };

  const handleDeleteAvatar = () => {
    avatarSrcRef.current = '';
    formik.setFieldValue('file', '');
    formik.setFieldValue('removeAvatar', true);
  };

  const inputArray = [
    {
      nameInput: 'city',
      title: 'City',
      value: formik.values.city,
      error: formik.errors.city,
      isTouched: formik.touched.city,
    },
    {
      nameInput: 'state',
      title: 'State',
      value: formik.values.state,
      error: formik.errors.state,
      isTouched: formik.touched.state,
    },
    {
      nameInput: 'zipCode',
      title: 'ZIP code',
      value: formik.values.zipCode,
      error: formik.errors.zipCode,
      isTouched: formik.touched.zipCode,
    },
  ];

  const styles = {
    card: { p: 5, borderRadius: 5 },
    container: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: { xs: 'unset', lg: '1fr 3fr' },
    },
    text: {
      py: 2,
      fontSize: '24px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    button: {
      px: 6,
      py: 1.5,
      borderRadius: 2,
      textTransform: 'capatilize',
    },
  };

  return (
    <StyledProfileChangeInfoArea>
      <Card sx={styles.card} component="form" onSubmit={formik.handleSubmit}>
        <Box sx={styles.container} className="styled-profile-change-info__section">
          <Typography component="p" sx={styles.text}>Account Info</Typography>
          <div>
            <ChangeAvatarArea
              avatarSrc={avatarSrcRef.current}
              handleChangeAvatar={handleChangeAvatar}
              handleDeleteAvatar={handleDeleteAvatar}
            />
            <div className="styled-profile-change-info__section__input-area">
              <Input
                nameInput="firstName"
                title="First Name"
                placeHolder="Enter your First Name"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                error={formik.errors.firstName}
                isTouched={formik.touched.firstName}
                onBlur={formik.handleBlur}
              />
              <Input
                nameInput="lastName"
                title="Last Name"
                placeHolder="Enter your Last Name"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                error={formik.errors.lastName}
                isTouched={formik.touched.lastName}
                onBlur={formik.handleBlur}
              />
              <PhoneInput
                nameInput="phone"
                title="Phone Number"
                placeHolder="+1 000 000 0000"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.errors.phone}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </Box>
        <Box sx={styles.container} className="styled-profile-change-info__section">
          <Typography component="p" sx={styles.text}>Address Info</Typography>
          <div>
            <Input
              nameInput="address"
              title="Address"
              placeHolder="Enter your Address"
              onChange={formik.handleChange}
              value={formik.values.address}
              error={formik.errors.address}
              isTouched={formik.touched.address}
              onBlur={formik.handleBlur}
            />
            <div className="styled-profile-change-info__section__input-area">
              {
                inputArray.map((item, index) => (
                  <Input
                    key={index}
                    nameInput={item.nameInput}
                    title={item.title}
                    placeHolder={`Enter your ${item.title}`}
                    onChange={formik.handleChange}
                    value={item.value}
                    error={item.error}
                    isTouched={item.isTouched}
                    onBlur={formik.handleBlur}
                  />
                ))
              }
            </div>
          </div>
        </Box>
        <Box sx={styles.container} className="styled-profile-change-info__section">
          <Typography component="p" sx={styles.text}>Discount Code</Typography>
          <div>
            <Input
              nameInput="code"
              title="Discount Code"
              placeHolder="Enter your Discount Code"
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.errors.code}
              isTouched={formik.touched.code}
              onBlur={formik.handleBlur}
            />
          </div>
        </Box>
        <div className="styled-profile-change-info__section__button-area">
          <Button sx={styles.button} type="submit" variant="contained">
            Save changes
          </Button>
        </div>
      </Card>
    </StyledProfileChangeInfoArea>
  );
};

export default ProfileChangeInfoArea;
