import styled, { css } from 'styled-components';

export default styled.div`
  background-color: ${({ theme }) => theme.mainTheme.color.background};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.mainTheme.shadow.extraLarge};
  position: absolute;
  z-index: 200;
  top: 30px;
  left: 7px;
  display: flex;
  flex-direction: column;

  .styled-drop-down-list__item {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding: 10px;
    width: 147px;
    cursor: pointer;

    :hover {
      background: ${({ theme }) => theme.mainTheme.color.grey.lightElements};
    }

    :first-child {
      ${({ theme }) => css`
        border-radius: ${theme.borderRadius} ${theme.borderRadius} 0 0;
      `}
    }

    :last-child {
      ${({ theme }) => css`
        border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
      `}
    }

    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.lightBackground};
    }
  }
`;
