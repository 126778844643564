export const userPaymentTableHead = [
  {
    title: '#',
    name: 'userTransactionId',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Amount Paid',
    name: 'amount',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Description',
    name: 'transferType',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Date',
    name: 'createdAt',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
];
