import styled, { css } from 'styled-components';

export default styled.div<{ isOpenDatePicker: boolean }>`
  position: absolute;
  z-index: 200;
  top: 4px;
  left: ${({ isOpenDatePicker }) => (isOpenDatePicker ? '-437px' : '0')};
  display: flex;
  column-gap: 4px;

  .styled-list {
    box-sizing: border-box;
    width: 175px;

    p {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      cursor: pointer;

      ${({ theme }) => css`
        background: ${theme.mainTheme.color.background};
        box-shadow: ${theme.mainTheme.shadow.extraLarge};

        :hover {
          background: ${theme.mainTheme.color.grey.lightElements};
        }

        :first-child {
          border-radius: ${theme.borderRadius} ${theme.borderRadius} 0 0;
        }

        :last-child {
          border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
        }

        :not(:last-child) {
          border-bottom: 1px solid ${theme.mainTheme.color.grey.lightBackground};
        }
      `}
  }
  }
`;
