import styled from 'styled-components';
import userAvatarImg from '../../assets/icons/user-avatar.svg';

export default styled.div`
  .styled-change-avatar-area__avatar-image {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: ${({ theme }) => theme.mainTheme.color.red.lightBackground};
    background-image: url(${userAvatarImg});
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    svg {
      stroke: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      object-fit: cover;
    }
  }

  .styled-change-avatar-area__avatar-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};

    &__input-file {
      display: none;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 30px;

      > div {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
        cursor: pointer;

      }
    }
  }

`;
