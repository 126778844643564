import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../Button';
import GhostButton from '../GhostButton';
import Modal from '../Modal';
import type { ChangeCodeType } from '../../types/codeTypes';

type PropsType = {
  onClick?: (couponId: number, code: string) => void;
  className?: string;
  onCloseModal?: () => void;
  rowCodeInfo: ChangeCodeType;

};

const DeleteCodeModal: React.FC<PropsType> = (props) => {
  const currentCode = props.rowCodeInfo.code || '';
  const formik = useFormik({
    initialValues: { rowCouponId: props.rowCodeInfo.couponId || 0, rowCode: props.rowCodeInfo.code || '' },
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        if (props?.onClick) {
          props?.onClick(values.rowCouponId, values.rowCode);
        }
        toast.success(`${currentCode} was deleted`);
      } catch (err) {
        toast.error(err);
      }
    },
  });

  return (
    <Modal>
      <div className="styled-modal__container__form__input">
        <h1>{currentCode}</h1>
      </div>
      <h1 className="styled-modal__container__text">Are you sure you want to delete this code?</h1>
      <form onSubmit={formik.handleSubmit} className="styled-modal__container__form">
        <div className="styled-modal__container__buttons-area">
          <GhostButton onClick={props?.onCloseModal}>
            <p className="text-3">Cancel</p>
          </GhostButton>
          <Button type="submit">
            <h4>Delete</h4>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteCodeModal;
