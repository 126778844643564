import React from 'react';
import Button from '../Button';
import GhostButton from '../GhostButton';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import Modal from '../Modal';

type PropsType = {
  onClick?: () => void;
  className?: string;
  onCloseModal?: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

} & React.PropsWithChildren;

const ErrorMappingModal: React.FC<PropsType> = (props) => {
  return (
    <Modal>
      <h3 className="styled-modal__container__text">File format is invalid</h3>
      <h1 className="styled-modal__container__text">The uploaded file does not have the proper .csv header. Please verify if the headers of the file you uploaded match the skip trace type you are trying to run and upload the file again. Note that these header fields need to be entered in the exact order as described in the documentation.</h1>
      <div className="styled-modal__container__buttons-area">
        <GhostButton onClick={props.onCloseModal}>
          <p className="text-3">Cancel</p>
        </GhostButton>
        <Button>
          <label id="label-file-upload" htmlFor="input-file-upload">
            <input
              type="file"
              id="input-file-upload"
              onChange={props.handleChange}
              accept=".csv"
            />
            <h4>Upload File</h4>
            <UploadIcon />
          </label>
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorMappingModal;
