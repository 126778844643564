import type { FilterRequestType, FilterType } from '../types/filterTypes';
import { changeDateToFilter } from './formattingDateHelper';

export const getCurrentFilter = (filter: FilterType): FilterRequestType => {
  const newFilter: FilterRequestType = { ...filter };
  if (newFilter.endDate) {
    newFilter.endDate = changeDateToFilter(newFilter.endDate);
  }
  if (!newFilter.sortField?.length) {
    delete newFilter.sortField;
    delete newFilter.sortDirect;
  }
  return newFilter;
};
