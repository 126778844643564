import { configureStore } from '@reduxjs/toolkit';
import userSlice from './user/userSlice';
import orderSlice from './order/orderSlice';

const store = configureStore({
  reducer: {
    userSlice,
    orderSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatchType = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;

export default store;
