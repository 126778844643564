import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../Button';
import GhostButton from '../GhostButton';
import Modal from '../Modal';
import Input from '../Input/Input';
import { codeSchema } from '../../types/validationSchemes';
import Checkbox from '../Checkbox/Checkbox';

type PropsType = {
  onClick?: (code: string, one_claim_per_user:boolean, discount: number, expires_at:string) => void;
  className?: string;
  onCloseModal?: () => void;
};

const AddCodeModal: React.FC<PropsType> = (props) => {
  const formik = useFormik({
    initialValues: { code: '',
      one_claim_per_user: true,
      discount: 0,
      /* eslint-disable */
      expires_at: ("0" + (
        new Date((new Date().getTime() + 365 * 24 * 60 * 60 * 1000)).getMonth() + 1)).slice(-2) + "/" + 
        ("0" + new Date((new Date().getTime() + 365 * 24 * 60 * 60 * 1000)).getDate()).slice(-2) + "/" + 
        (new Date((new Date().getTime() + 365 * 24 * 60 * 60 * 1000)).getFullYear()) },
    validationSchema: codeSchema,
    validateOnMount: false,
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        if (props?.onClick) {
          props?.onClick(values.code, values.one_claim_per_user, values.discount, values.expires_at);
        }
        toast.success(`The price of ${0} has been changed`);
      } catch (err) {
        toast.error(err);
      }
    },
  });

  return (
    <Modal>
      <div className="styled-modal__container__form__input">
        <h3>Add new Discount Code</h3>
      </div>
      <h1 className="styled-modal__container__text">You can change the price if necessary</h1>
      <form onSubmit={formik.handleSubmit} className="styled-modal__container__form">
        <div className="styled-modal__container__form__input">
          <div>
            <Input
              nameInput="code"
              title="Discount Code"
              placeHolder="Discount Code"
              type="string"
              step="0.01"
              min="0"
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.errors.code}
              isTouched={formik.touched.code}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="styled-signup-page__container__check-area">
              <Checkbox
                name="one_claim_per_user"
                onChange={formik.handleChange}
                checked={formik.values.one_claim_per_user}
              />

              <div className="styled-signup-page__container__check-area__links-area">
                <p className="text-4">One claim per user</p>
              </div>
          </div>
          <div>
            <Input
              nameInput="discount"
              title="Discount %"
              placeHolder="Discount $"
              type="number"
              step="1"
              min="0"
              onChange={formik.handleChange}
              value={formik.values.discount}
              error={formik.errors.discount}
              isTouched={formik.touched.discount}
              onBlur={formik.handleBlur}
            />
          </div>
          <div>
            <Input
              nameInput="expires_at"
              title="Expires At mm/dd/yyyy"
              placeHolder="Expires At mm/dd/yyyy"
              type="string"
              onChange={formik.handleChange}
              value={formik.values.expires_at}
              error={formik.errors.expires_at}
              isTouched={formik.touched.expires_at}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className="styled-modal__container__buttons-area">
          <GhostButton onClick={props?.onCloseModal}>
            <p className="text-3">Cancel</p>
          </GhostButton>
          <Button type="submit">
            <h4>Save changes</h4>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCodeModal;
