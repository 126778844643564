import type { AxiosResponse } from 'axios';
import type { FilterRequestType } from '../../types/filterTypes';
import type { DataFileType, DataType, FieldType, UploadAdminOrderRequestType, UploadOrderRequestType, DownloadAdminOrderRequestType } from '../../types/orderTypes';
import instance from '../index';

export enum OrderApiPathENUM {
  GET_BY_FILTER = '/api/user/file',
  UPLOAD_FILE = '/api/file/s3-upload',
  PROCESS_OUTPUT_FILE_SFTP = '/api/file/s3-process-output-sftp',
  DELETE_FILE = '/api/file/',
  UPLOAD_FIELDS = '/api/file/upload-fields',
  DOWNLOAD_OUTPUT_FILE = '/api/file/s3-download-output',
  GET_ADMIN_FILES = '/api/admin/s3-list',
  DOWNLOAD_OUTPUT_FILE_BY_ADMIN = '/api/admin/s3-download-output',
  PROCESS_OUTPUT_FILE_BY_ADMIN = '/api/admin/s3-process-output-api',
  DOWNLOAD_INPUT_FILE_BY_ADMIN = '/api/admin/s3-download-input',
  UPLOAD_ADMIN_FILE = '/api/admin/s3-upload',
}

export const orderAPI = {
  getOrders: async (filter: FilterRequestType): Promise<AxiosResponse<DataType>> => {
    return instance.get(OrderApiPathENUM.GET_BY_FILTER, { params: { ...filter } });
  },

  getAdminOrders: async (filter: FilterRequestType): Promise<AxiosResponse<DataType>> => {
    return instance.get(OrderApiPathENUM.GET_ADMIN_FILES, { params: { ...filter } });
  },

  uploadFile: async (file: UploadOrderRequestType): Promise<string> => {
    return instance.post(OrderApiPathENUM.UPLOAD_FILE, file);
  },

  processOutputFileSFTP: async (userFileId: number): Promise<string> => {
    return instance.get(OrderApiPathENUM.PROCESS_OUTPUT_FILE_SFTP, { params: { userFileId } });
  },

  deleteFile: async (fileName: string): Promise<string> => {
    return instance.delete(OrderApiPathENUM.DELETE_FILE, { params: { fileName } });
  },

  uploadFields: async (values: Array<FieldType>): Promise<string> => {
    return instance.post(OrderApiPathENUM.UPLOAD_FIELDS, values);
  },

  downloadOutputFile: async (name: string): Promise<AxiosResponse<DataFileType>> => {
    return instance.get(OrderApiPathENUM.DOWNLOAD_OUTPUT_FILE, { params: { name } });
  },

  downloadOutputAdminFile: async (file: DownloadAdminOrderRequestType): Promise<
    AxiosResponse<DataFileType>> => {
    return instance.get(OrderApiPathENUM.DOWNLOAD_OUTPUT_FILE_BY_ADMIN, { params: { ...file } });
  },

  processOutputAdminFile: async (userFileId: number): Promise<
    AxiosResponse<DataFileType>> => {
    return instance.get(OrderApiPathENUM.PROCESS_OUTPUT_FILE_BY_ADMIN, { params: { userFileId } });
  },

  downloadInputAdminFile: async (file: DownloadAdminOrderRequestType): Promise<
    AxiosResponse<DataFileType>> => {
    return instance.get(OrderApiPathENUM.DOWNLOAD_INPUT_FILE_BY_ADMIN, { params: { ...file } });
  },

  uploadAdminFile: async (file: UploadAdminOrderRequestType): Promise<string> => {
    return instance.post(OrderApiPathENUM.UPLOAD_ADMIN_FILE, file);
  },
};
