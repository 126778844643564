import React from 'react';
import ProfileChangeInfoArea from '../ProfileChangeInfoArea/ProfileChangeInfoArea';
import ProfileChangePasswordArea from '../ProfileChangePasswordArea/ProfileChangePasswordArea';
import StyledProfileMainContent from './ProfileMainContent.styles';
import PageTitle from '../../../../components/PageTitle';
import SubscriptionArea from '../SubscriptionArea';

const ProfileMainContent = () => {
  return (
    <StyledProfileMainContent>
      <PageTitle title="Edit Profile" />
      <ProfileChangeInfoArea />
      <SubscriptionArea />
      <ProfileChangePasswordArea />
    </StyledProfileMainContent>
  );
};

export default ProfileMainContent;
