import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import { ReactComponent as ChevronLeftIcon } from '../../../../assets/icons/chevron-left.svg';
import StyledResetPassword from './GetCodePage.styles';
import CodeInput from '../../../../components/CodeInput/CodeInput';
import GhostButton from '../../../../components/GhostButton/GhostButton';
import { resetPassCodeSchema } from '../../../../types/validationSchemes';
import { checkIsCodeErrorMessage } from '../../../../utils/errorCheckHelper';
import userThunks from '../../../../store/user/userThunk';
import { useAppDispatch } from '../../../../store/hooks';

type PropsType = {
  onSubmit: (value: number) => void;
  onReSendCode: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
};

const GetCodePage: React.FC<PropsType> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [timerSeconds, setSeconds] = useState('59');
  const [timerActive, setTimerActive] = React.useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (+timerSeconds > 0 && timerActive) {
      timeoutId = setTimeout(setSeconds, 1000, +timerSeconds > 10 ? `${+timerSeconds - 1}` : `0${+timerSeconds - 1}`);
    } else {
      setTimerActive(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timerSeconds, timerActive]);

  const formikCode = useFormik({
    initialValues: { email: props.email, code: '' },
    validationSchema: resetPassCodeSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        await dispatch(userThunks.resetPasswordSecondPart(values)).unwrap();
        props.onSubmit(3);
      } catch (err) {
        if (checkIsCodeErrorMessage(err)) {
          setErrors({ code: err });
        } else {
          toast.error(err);
        }
      }
    },
  });

  const onChangeCode = (value: string) => {
    formikCode.setFieldValue('code', value);
  };

  const handleNavToLoginPage = () => {
    navigate('/log-in');
  };

  return (
    <StyledResetPassword>
      <div className="styled-reset-page__content">
        <GhostButton onClick={handleNavToLoginPage}>
          <ChevronLeftIcon />
          <p className="text-3">Back to Log In</p>
        </GhostButton>
        <div className="styled-reset-page__title-area">
          <p className="website-header">reset password</p>
          <p className="text-2 styled-reset-page__title-area__description">A verification code has been sent to <span className="text-3">{props.email}</span></p>
        </div>
        <div className="styled-reset-page__content__forms-area">
          <form onSubmit={formikCode.handleSubmit} className="styled-reset-page__content__form">
            <div className="styled-reset-page__main-content">
              <div className="styled-reset-page__inputs-area">
                <CodeInput
                  nameInput="code"
                  title="Verification code"
                  placeHolder="0"
                  onChange={onChangeCode}
                  value={formikCode.values.code}
                  error={formikCode.errors.code}
                />
              </div>
            </div>

            <div className="styled-reset-page__content__form__button-area">
              <Button type="submit">
                <h4>Verify</h4>
              </Button>
            </div>
          </form>

          <form onSubmit={props.onReSendCode}>
            <div className="styled-reset-page__links-area__footer">
              <p className="text-2 styled-reset-page__link__description">Didn’t receive a code?</p>
              {
                timerActive
                  ? (
                    <p className="text-3 styled-reset-page__link__description">Send in 00:{timerSeconds}</p>
                  ) : (
                    <GhostButton type="submit">
                      <p className="text-3">Send again</p>
                    </GhostButton>
                  )
              }
            </div>
          </form>
        </div>
      </div>
    </StyledResetPassword>
  );
};

export default GetCodePage;
