import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import AvatarStyles from './Avatar.styles';

function Avatar() {
  const userProfile = useAppSelector((store) => store.userSlice.user);
  const navigate = useNavigate();

  const shortName = useMemo(() => {
    const newName = [];
    if (userProfile?.firstName && userProfile?.lastName) {
      newName.push(userProfile?.firstName.charAt(0));
      newName.push(userProfile?.lastName.charAt(0));
    } else {
      newName.push(userProfile.email.charAt(0));
    }

    return newName.join('');
  }, [userProfile.email, userProfile?.firstName, userProfile?.lastName]);

  return (
    <AvatarStyles>
      <div className="avatar-area" onClick={() => navigate('/profile')}>
        {userProfile.avatar ? (
          <img src={userProfile?.avatar} />
        ) : (
          <h3>{shortName}</h3>
        )}
      </div>
      <div>
        <p>Hello,</p>
        <p className="avatar-text">{userProfile?.firstName} {userProfile?.lastName?.charAt(0)}.</p>
      </div>
    </AvatarStyles>
  );
}

export default Avatar;
