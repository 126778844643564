import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import { StyledButton, StyledMenu } from './MobileMenu.style';

interface IMenu{
  icon: React.ReactElement;
  title: string;
  url: string;
}
interface IMobileMenu{
  onClick: (url: string) => void;
  menuArray: IMenu[];
  isCurrentTab: (item: IMenu) => boolean;
}

const MobileMenu = ({ onClick, menuArray, isCurrentTab }: IMobileMenu) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = React.useState(
    menuArray.find((item) => isCurrentTab(item)) || menuArray[0],
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem: IMenu) => {
    setSelectedMenu(menuItem);
    handleClose();
    onClick(menuItem.url);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <StyledButton
        id="custom-menu-button"
        aria-controls={open ? 'custom-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {selectedMenu.icon}
          <Typography component="span">{selectedMenu.title}</Typography>
        </Box>
      </StyledButton>
      <StyledMenu
        id="custom-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'custom-menu-button',
        }}
      >
        {menuArray.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item)}
            disableRipple
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {item.icon}
              <Typography component="span">{item.title}</Typography>
            </Box>
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export default MobileMenu;
