import styled from 'styled-components';

export default styled.tr`
  th {
    > div {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    cursor: default;
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};

    :first-child {
      border-radius: 4px 0 0 0;
      color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
    }

    :last-child {
      border-radius: 0 4px 0 0;
    }
  }

  .styled-table-head__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    &-pointer {
      cursor: pointer;
    }
  }

  .default-color-icon {
    cursor: pointer;
    fill: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
  }

  .asc-color-icon {
    cursor: pointer;
    fill: ${({ theme }) => theme.mainTheme.color.red.primary};
    color: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
  }

  .desc-color-icon {
    cursor: pointer;
    fill: ${({ theme }) => theme.mainTheme.color.typography.lightGreyText};
    color: ${({ theme }) => theme.mainTheme.color.red.primary};
  }

  .styled-table-head__dropdown-list {
    height: 24px;
    position: relative;
  }

`;
