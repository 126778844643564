import React, { useEffect, useState } from 'react';
import type { FocusedInput, OnDatesChangeProps } from '@datepicker-react/hooks';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';
import StyledDatePicker from './DatePicker.styles';
import type { FilterType } from '../../types/filterTypes';
import DatepickerContext from './datepickerContext';
import Month from './Month';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
};

const DatePicker: React.FC<PropsType> = (props) => {
  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE as FocusedInput,
  });

  const handleDateChange = (data: OnDatesChangeProps) => {
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }
  };

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToNextMonthsByOneMonth,
    goToPreviousMonthsByOneMonth,
    goToNextYear,
    goToPreviousYear,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
  });

  useEffect(() => {
    props.setFilter('endDate', state.endDate);
    props.setFilter('startDate', state.startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.endDate, state.startDate]);

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <StyledDatePicker activeMonthsLength={activeMonths?.length}>

        <div className="styled-date-picker__container">
          {activeMonths.length > 0
            ? activeMonths?.map((month) => (
              <Month
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
                goToNextMonthsByOneMonth={goToNextMonthsByOneMonth}
                goToPreviousMonthsByOneMonth={goToPreviousMonthsByOneMonth}
                goToNextYear={goToNextYear}
                goToPreviousYear={goToPreviousYear}
              />
            )) : null}
        </div>
      </StyledDatePicker>
    </DatepickerContext.Provider>
  );
};

export default DatePicker;
