import React, { useMemo } from 'react';
import StyledTableBodyRow from './TableBodyRowPayment.styles';
import type { PaymentType } from '../../types/userTypes';
import { formattingDate } from '../../utils/formattingDateHelper';

type PropsType = {
  item: PaymentType;
  className?: string;
};

const TableBodyRowPayment: React.FC<PropsType> = (props) => {
  const { item, className = '' } = props;

  const correctDisplayInfo = (info: number | string | null) => (info || '-');

  const orderValueArray = useMemo(() => {
    return [
      { content: item.userTransactionId },

      { content: `$${item.amount.toFixed(2)}` },

      { content: correctDisplayInfo(item.transferType) },

      { content: formattingDate(item.createdAt) },

    ];
  }, [item]);

  return (
    <StyledTableBodyRow className={className}>
      {orderValueArray.map((item, index) => (
        <td key={index}>
          <div>
            {item.content}
          </div>
        </td>
      ))}
    </StyledTableBodyRow>
  );
};

export default TableBodyRowPayment;
