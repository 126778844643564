import React from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import type { IPaymentDetails } from '../../types';
import type { CardType } from '../../../../types/userTypes';
import { useAppDispatch } from '../../../../store/hooks';
import { paymentApi } from '../../../../api/services/userApi';
import userThunk from '../../../../store/user/userThunk';

type PropsType = {
  paymentMethod: CardType;
};

const PaymentDetails: React.FC<PropsType> = ({ paymentMethod }) => {
  const dispatch = useAppDispatch();
  const paymentDetails: IPaymentDetails = {
    name: paymentMethod?.billing_details.name as string || '-',
    address: paymentMethod?.billing_details.address.country as string || '-',
    card: `**** **** **** ${paymentMethod?.card.last4 || '****'}`,
    zip: paymentMethod?.billing_details.address.postal_code as string || '-',
  };
  const deleteCard = async () => {
    try {
      await paymentApi.deleteCard(paymentMethod.id);
      toast.success('the card has been deleted');
      await dispatch(userThunk.getCards());
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Card sx={{ p: 3, borderRadius: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 3 }}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontSize: '24px',
            fontWeight: 900,
            letterSpacing: '1px',
          }}
        >
          Current Billing Info
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: { xs: 2, sm: 5 },
                flexWrap: 'wrap',
              }}
            >
              {Object.entries(paymentDetails)?.map(([name, value], index) => (
                <Box key={index}>
                  <Typography sx={{ fontWeight: 300, textTransform: 'capitalize' }}>
                    {name}
                  </Typography>
                  <Typography sx={{ fontWeight: 700, textTransform: 'capitalize' }}>
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              endIcon={<DeleteForeverIcon fill="white" />}
              sx={{
                px: 6,
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                float: { xs: 'unset', sm: 'right' },
                width: { xs: '100%', sm: 'unset' },
              }}
              variant="contained"
              onClick={deleteCard}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default PaymentDetails;
