import styled from 'styled-components';

export const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  .container{
    width: 1520px;
    position: relative;
  }

  .left-logo-container {
    position: absolute;
    width: 400px;
    height: 80px;
    background: ${({ theme }) => theme.mainTheme.color.bgColor};
    border-radius: 0 0 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      width: 250px;
    }
  }

  .social-media-icons-right-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 80px;
    background: ${({ theme }) => theme.mainTheme.color.bgColor};
    border-radius: 0 0 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    @media (max-width: 1024px) {
      width: 180px;
    }
  }

  .center-curve-container {
    position: absolute;
    top: 0;
    left: 400px;
    right: 200px;
    height: 80px;
    background: linear-gradient(0deg, #222222, #222222);
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      left: 250px;
      right: 180px;
    }
  }

  .center-curve-container-text {
    font-weight: 300;
    font-size: 15px;
    line-height: 22.5px;
    color: #acacac;
  }

  .links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    height: 175px;
    justify-content: space-evenly;
    background: linear-gradient(
        95.55deg,
        rgba(34, 34, 34, 0) 85.12%,
        #a2461f 101.01%
      ),
      linear-gradient(273.28deg, rgba(34, 34, 34, 0) 85.98%, #ca8722 104.13%),
      linear-gradient(0deg, #222222, #222222);
    border-radius: 15px;
    @media (max-width: 1520px) {
      border-radius: 0px;
    }
  }

  .link-container-label-text {
    font-weight: 300;
    font-size: 15px;
    line-height: 22.5px;
    color: #acacac;
    @media (max-width: 1024px) {
      font-weight: 250;
      font-size: 12px;
      line-height: 20.5px;
    }
  }

  .styled-footer__link {
    cursor: pointer;
    color: '#FCF8F8';
    text-decoration-line: underline;
    color: #fcf8f8;
    font-weight: 300;
    font-size: 15px;
    line-height: 22.5px;
  }
`;

export const StyledMobileFooter = styled.footer`
  position: relative;

  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
      273.28deg,
      rgba(34, 34, 34, 0) 65.98%,
      #ca8722 154.13%
    ),
    linear-gradient(0deg, #222222, #222222);
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ theme }) => theme.mainTheme.color.bgColor};
    width: 60%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 0px 0px 15px 15px;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    gap: 30px;

    .styled-footer__link {
      text-decoration: underline;
      color: #fcf8f8;
    }
  }

  .social-media-icons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .center-curve-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .footer-label-text {
    font-weight: 300;
    font-size: 15px;
    line-height: 22.5px;
    color: #acacac;
  }

  .styled-footer__link {
    cursor: pointer;
    color: '#FCF8F8';
    text-decoration-line: underline;
    color: #fcf8f8;
    font-weight: 300;
    font-size: 15px;
    line-height: 22.5px;
  }
`;
