import { Button, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '14px',
    width: '100%',
    background:
      'linear-gradient(177.14deg, #222222 24.27%, #E35011 195.99%, #A21F22 275.89%), linear-gradient(6.86deg, rgba(34, 34, 34, 0) 66.64%, #CA8722 139.07%)',
    color: '#FFFFFF',
    boxShadow: 'none',
  },
  '& .MuiMenuItem-root': {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: 24,
      color: '#FFFFFF',
      marginRight: '16px',
    },
    '& .MuiTypography-root': {
      marginLeft: '8px',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '76px',
  gap: '10px',
  borderRadius: '14px',
  background:
    'linear-gradient(177.14deg, #222222 24.27%, #E35011 195.99%, #A21F22 275.89%), linear-gradient(6.86deg, rgba(34, 34, 34, 0) 66.64%, #CA8722 139.07%)',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
}));
