import React from 'react';
import Footer from '../../components/Footer';
import StyledUnauthorizedPage from './UnauthorizedPage.styles';
import Navbar from '../../components/Navbar';

type PropsType = {
  children: JSX.Element;
};

const UnauthorizedPage: React.FC<PropsType> = (props) => {
  return (
    <StyledUnauthorizedPage>
      <Navbar />
      <div className="styled-unauthorized-page__main-content">
        {props.children}
      </div>
      <Footer />
    </StyledUnauthorizedPage>
  );
};

export default UnauthorizedPage;
