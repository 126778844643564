import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 60px;

  .styled-final-order__container {
    width: 100%;
    max-width: 400px;
    height: 100%;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
    border-radius: 20px;
    background: ${({ theme }) => theme.mainTheme.color.background};
    
    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      row-gap: 16px;

      h3 {
        color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
      }

      .text-2 {
        color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      }
    }

    &__info-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
    }

    &__total-price {
      color: ${({ theme }) => theme.mainTheme.color.red.primary} !important;
    }
  }

  .styled-final-order__link-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};

    &__label-text {
      text-decoration-line: underline;
      cursor: pointer;
      color: ${({ theme }) => theme.mainTheme.color.red.primary};
    }
  }

`;
