import Papa from 'papaparse';

interface ICsvData {
  [key: string ]: string;
}

type DDType = {
  records: number;
  fields: Array<string>;
  data: Array<ICsvData>;
};

export const parseFile = async (file: File) => {
  const data: DDType = await new Promise((resolve) => {
    const onComplete = (results: Papa.ParseResult<unknown>) => {
      return resolve({
        fields: results.meta.fields as Array<string>,
        records: results.data.length + 1,
        data: results.data as Array<ICsvData>,
      });
    };

    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete(results) {
        onComplete(results);
      },
    });
  });

  return data;
};

export const createCsvFile = async (ICsvData: ICsvData[], filter?: (row: ICsvData) => ICsvData)
: Promise<string> => {
  return Papa.unparse(filter ? ICsvData.map(filter) : ICsvData);
};
