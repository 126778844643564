export const orderAdminTableHead = [
  {
    title: '#',
    name: 'userFileId',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Order name',
    name: 'name',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Date',
    name: 'createdAt',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'User name',
    name: 'fullName',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'User email',
    name: 'email',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Price',
    name: 'price',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Status',
    name: 'status',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: true,
  },
  {
    title: 'Notes',
    name: 'notes',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },

  {
    title: 'File',
    name: 'userFile',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Action',
    name: 'action',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
];
