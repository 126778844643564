export const userAdminTableHead = [
  {
    title: '#',
    name: 'userId',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'User name',
    name: 'fullName',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'User email',
    name: 'email',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Phone Number',
    name: 'phone',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Date',
    name: 'createdAt',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'LLC Skip Rate per record',
    name: 'llcCost',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Other Skip Rate per record',
    name: 'rowCost',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Balance',
    name: 'balance',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Action',
    name: 'action',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
];
