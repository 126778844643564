import { Outlet } from 'react-router-dom';
import StyledPaymentsPage from './PaymentsPage.styles';

const PaymentsPage = () => {
  return (
    <StyledPaymentsPage>
      <Outlet />
    </StyledPaymentsPage>
  );
};

export default PaymentsPage;
