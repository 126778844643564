import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { arrRoutes } from '../../routes';

const RouterPages = () => {
  return (
    <Routes>
      {arrRoutes.length && arrRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.element}>
          {item?.children ? (
            item.children.map((elem, index) => (
              <Route key={index} path={elem.path} element={elem.element} />
            ))
          ) : null}
        </Route>
      ))}
    </Routes>
  );
};

export default RouterPages;
