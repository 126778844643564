import styled from 'styled-components';

export default styled.div`

width: 100%;
min-height: 100vh;
padding: 40px;

          /* Font Definitions */
          @font-face {
            font-family: Wingdings;
            panose-1: 5 0 0 0 0 0 0 0 0 0;
          }
          @font-face {
            font-family: "Cambria Math";
            panose-1: 2 4 5 3 5 4 6 3 2 4;
          }
          @font-face {
            font-family: Aptos;
          }
          /* Style Definitions */
          p.MsoNormal, li.MsoNormal, div.MsoNormal {
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8.0pt;
            margin-left: 0in;
            line-height: 115%;
            font-size: 14.0pt;
            font-family: "Aptos", sans-serif;
          }
          a:link, span.MsoHyperlink {
            color: #467886;
            text-decoration: underline;
          }
          p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph {
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8.0pt;
            margin-left: .5in;
            line-height: 115%;
            font-size: 14.0pt;
            font-family: "Aptos", sans-serif;
          }
          p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst {
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            margin-left: .5in;
            line-height: 115%;
            font-size: 14.0pt;
            font-family: "Aptos", sans-serif;
          }
          p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle {
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 0in;
            margin-left: .5in;
            line-height: 115%;
            font-size: 14.0pt;
            font-family: "Aptos", sans-serif;
          }
          p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast {
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8.0pt;
            margin-left: .5in;
            line-height: 115%;
            font-size: 14.0pt;
            font-family: "Aptos", sans-serif;
          }
          .MsoChpDefault {
            font-size: 14.0pt;
            font-family: "Aptos", sans-serif;
          }
          .MsoPapDefault {
            margin-bottom: 8.0pt;
            line-height: 115%;
          }
          @page WordSection1 {
            size: 8.5in 11.0in;
            margin: 1.0in 1.0in 1.0in 1.0in;
          }
          div.WordSection1 {
            page: WordSection1;
          }
          /* List Definitions */
          ol {
            margin-bottom: 0in;
          }
          ul {
            margin-bottom: 0in;
          }
        
`;
