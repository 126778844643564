export const orderStatusFilter = [
  {
    title: 'Show all',
    value: '',
  },
  {
    title: 'Uploaded',
    value: 'Uploaded',
  },
  {
    title: 'Processing',
    value: 'Processing',
  },
  {
    title: 'Successful',
    value: 'Successful',
  },
  {
    title: 'Not found',
    value: 'Not Found',
  },
];
