import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorReturn = (err: any) => {
  let currentError = err;
  if (!err.response) {
    currentError = err.message;
  }
  if (err instanceof AxiosError) {
    currentError = err.response?.data.message;
  }
  return currentError;
};
