import styled from 'styled-components';

export default styled.div`
  padding: 10px;
  width: 100%;
  max-width: 498px;

  .styled-reset-page__content {
    height: 100%;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
  }

  .styled-reset-page__link {
    cursor: pointer;
    text-decoration: none;
    fill: ${({ theme }) => theme.mainTheme.color.red.primary};
    color: ${({ theme }) => theme.mainTheme.color.red.primary};
  }

  .styled-reset-page__link__description {
    text-decoration: none;
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
  }

  .styled-reset-page__links-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .styled-reset-page__links-area__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 4px;
  }

  .styled-reset-page__title-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    text-align: center;

    &__description {
      color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      max-width: 340px;
    }
  }

  .styled-reset-page__main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-end;
  }

  .styled-reset-page__inputs-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
`;
