import React, { useEffect } from 'react';
import StyledBalanceTableArea from './BalanceTableArea.styles';
import Table from '../Table';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Pagination from '../Pagination';
import { changeUserFilter } from '../../store/user/userSlice';
import { userPaymentTableHead } from '../../store/user/userPaymentTableHead';
import userThunk from '../../store/user/userThunk';

const BalanceTableArea = () => {
  const userSlice = useAppSelector((state) => state.userSlice);
  const filter = useAppSelector((state) => state.userSlice.filter);
  const dispatch = useAppDispatch();
  const onChangeFilter = (title: string, value: string | Date | null) => {
    dispatch(changeUserFilter({ title, value }));
  };

  const handleChangePage = (page: number) => {
    dispatch(changeUserFilter({ title: 'page', value: `${page}` }));
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(userThunk.getPayments(filter)).unwrap();
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, filter]);

  return (
    <StyledBalanceTableArea>
      {
        userSlice.addition.isLoading ? (
          <div className="styled-app__loader-mini" />
        ) : (
          <>
            <Table
              filter={filter}
              setFilter={onChangeFilter}
              mainArray={userSlice.payments}
              tableHead={userPaymentTableHead}
            />
            {
              userSlice?.options ? (
                <Pagination
                  currentPage={userSlice?.options.page}
                  totalCount={userSlice?.options.totalPages}
                  pageSize={userSlice?.options.perPage}
                  onPageChange={handleChangePage}
                  countItems={userSlice?.options.totalRecords}
                />
              ) : null
            }
          </>
        )
      }
    </StyledBalanceTableArea>
  );
};

export default BalanceTableArea;
