import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  border: none;
  flex-direction: column;
  overflow: scroll;
  border-radius: 20px;
  position: relative;
  height: 380px;


  table {
    background: ${({ theme }) => theme.mainTheme.color.background};
    width: 100%;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    position: absolute;

    thead {

      th {
        text-align: center;
        border: none;
        padding: 10px 15px;
        background: ${({ theme }) => theme.mainTheme.color.grey.lightBackground};
        border-top: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
        font-size: 15px;
        font-weight: 300;
      }
    }

    tr {
      th, td {
        :first-child {
          border-left: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
        }

        :last-child {
          border-right: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
        }
      }
    }
  }

`;
