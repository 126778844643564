import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import config from '../config';

const useGoogleAnalytics = () => {
  const { trackingId, enabled } = config.googleAnalytics;

  useEffect(() => {
    if (enabled && trackingId) {
      ReactGA.initialize(trackingId, { testMode: false });
    } else {
      console.error('Google Analytics ID not set');
    }
  }, [enabled, trackingId]);

  return enabled;
};

export default useGoogleAnalytics;
