import styled from 'styled-components';
import '@fontsource/poppins/900.css';

export default styled.div`
  width: 100%;
  max-width: 500px;

  .styled-reset-page__content {
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 26px;
    border-radius: 20px;
    background: ${({ theme }) => theme.mainTheme.color.background};
  }

  .styled-reset-page__title-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    text-align: center;

    &__title {
      font-size: 25px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: uppercase;
    }

    &__description {
      color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.45px;
    }
  }

  .styled-reset-page__main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    align-items: center;
  }

  .styled-reset-page__inputs-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`;
