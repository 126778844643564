import React, { useEffect } from 'react';
import StyledDashboardPage from './DashboardPage.styles';
import DashBoardHeader from './components/DashBoardHeader';
import DashBoardMainContent from './components/DashBoardMainContent';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { changeFilter } from '../../store/order/orderSlice';
import userThunk from '../../store/user/userThunk';
import { changeUserFilter } from '../../store/user/userSlice';
import { useLocationName } from '../../utils/checkLocationHelper';

const DashboardPage = () => {
  const filter = useAppSelector((state) => state.orderSlice.filter);
  const userFilter = useAppSelector((state) => state.userSlice.filter);
  const isAdminUser = useAppSelector((state) => state.userSlice.user.isSuperUser);
  const dispatch = useAppDispatch();
  const locationName = useLocationName();
  const isOrderPage = locationName === 'orders';
  const isCodePage = locationName === 'codes';
  const isUserPage = locationName === 'users';

  const onChangeFilter = (title: string, value: string | Date | null) => {
    dispatch(changeFilter({ title, value }));
  };

  const onChangeUserFilter = (title: string, value: string | Date | null) => {
    dispatch(changeUserFilter({ title, value }));
  };

  useEffect(() => {
    (async () => {
      try {
        if (isAdminUser) {
          if (isOrderPage) {
            await dispatch(userThunk.getAdminOrderCount()).unwrap();
          }
          if (isUserPage) {
            await dispatch(userThunk.getAdminUserCount()).unwrap();
          }
          if (isCodePage) {
            await dispatch(userThunk.getAdminCodeCount()).unwrap();
          }
        } else {
          await dispatch(userThunk.getOrderCount()).unwrap();
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, isAdminUser, isOrderPage, isUserPage, isCodePage]);

  const currentFilter = isUserPage ? userFilter : filter;
  const currentSetFilter = (isUserPage || isCodePage) ? onChangeUserFilter : onChangeFilter;

  return (
    <StyledDashboardPage>
      <DashBoardHeader />
      <DashBoardMainContent filter={currentFilter} setFilter={currentSetFilter} />
    </StyledDashboardPage>
  );
};

export default DashboardPage;
