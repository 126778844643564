import styled from 'styled-components';

export default styled.div`
  color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
  display: flex;
  justify-content: space-between;

  .pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-container__icon {
      fill: ${({ theme }) => theme.mainTheme.color.red.primary};
    }

    .pagination-item {
      height: 24px;
      width: 24px;
      text-align: center;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border-radius: 2px;

      :hover {
        background-color: ${({ theme }) => theme.mainTheme.color.grey.background};
        color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
        cursor: pointer;
      }
    }

    .dots
    {
      :hover {
        background-color: transparent;
        cursor: default;
      }
    }

    .disabled {
      pointer-events: none;

      :hover {
        background-color: transparent;
        cursor: not-allowed;
      }
    }

    .selected {
      background-color: ${({ theme }) => theme.mainTheme.color.grey.background};
      color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
    }

    .chevron {
      padding: 0;
      margin: 0;
    }
  }
`;
