import { useLocation } from 'react-router-dom';

export const useLocationName = () => {
  const location = useLocation();
  return location.pathname.split('/')[1];
};

export const useLocationSecondName = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};

export const usePathIncludeLocation = (path: string) => {
  const location = useLocation();
  return location.pathname.includes(path);
};

export const useGetLocationPath = () => {
  const location = useLocation();
  return location.pathname;
};
