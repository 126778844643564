import MobileFrame from './components/MobileFrame/MobileFrame';
import WebFrame from './components/WebFrame/WebFrame';

type PropsType = {
  title: string;
  subTitle?: string;
  buttonText?: string;
};

const Frame: React.FC<PropsType> = (props) => {
  return (
    <>
      <MobileFrame {...props} />
      <WebFrame {...props} />
    </>
  );
};

export default Frame;
