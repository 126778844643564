import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .styled-unauthorized-page__main-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
