import styled, { css } from 'styled-components';

export default styled.div<{ error?: string; name: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .styled-input__container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .styled-input__svg {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: auto;
    stroke: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
  }

  .styled-input__label {
    color: ${({ theme }) => theme.mainTheme.color.grey.dark};
  }

  .styled-input__input {
    ${({ theme }) => css`
      font-family: ${theme.font.family};
      background: ${theme.mainTheme.color.grey.lightBackground};
      border-radius: ${theme.borderRadius};
      outline: none;
      border: 1px solid ${theme.mainTheme.color.grey.background};
      color: ${theme.mainTheme.color.grey.dark};
      width: 100%;
      height: 48px;
      padding:0 14px 0 20px;

      :focus {
        border: 1px solid ${theme.mainTheme.color.typography.lightGreyText};
      }

      ::placeholder {
        color: ${theme.mainTheme.color.typography.lightGreyText};
      }
    `}
  }

  .styled-input__error-info {
    color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
    align-items: center;
  }

  ${({ theme, error, name }) => {
    if (error) {
      return css`
        #${name}{
          border: 1px solid ${theme.mainTheme.color.red.bright};
          :valid ~ .styled-input__label{
            color: ${theme.mainTheme.color.red.bright};
          }
        }
        #${name}__error{
          color: ${theme.mainTheme.color.red.bright};
        }
      `;
    }
  }}
    `;
