import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import userThunks from '../../store/user/userThunk';
import StyledResetPassword from './ResetPasswordPage.styles';
import PutEmailPage from './components/PutEmailPage';
import GetCodePage from './components/GetCodePage';
import PutNewPasswordPage from './components/PutNewPasswordPage/PutNewPasswordPage';
import ChangedPasswordPage from './components/ChangedPasswordPage/ChangedPasswordPage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { resetPassEmailSchema } from '../../types/validationSchemes';
import { checkIsEmailErrorMessage } from '../../utils/errorCheckHelper';

const ResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.userSlice.addition.isLoading);

  const [pageNumber, setPageNumber] = useState(1);
  const currentEmail = useRef('');

  const onChangePage = (value: number) => {
    setPageNumber(value);
  };

  const formikEmail = useFormik({
    initialValues: { email: currentEmail.current },
    validationSchema: resetPassEmailSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        currentEmail.current = values.email;
        await dispatch(userThunks.resetPasswordFirstPart(values)).unwrap();
        onChangePage(2);
      } catch (err) {
        if (checkIsEmailErrorMessage(err)) {
          setErrors({ email: err });
        } else {
          toast.error(err);
        }
      }
    },
  });

  if (isLoading) {
    return (
      <StyledResetPassword>
        <div className="styled-app__loader-mini" />
      </StyledResetPassword>
    );
  }

  return (
    <>
      {
        pageNumber === 1
          ? (
            <PutEmailPage
              onSubmit={formikEmail.handleSubmit}
              email={formikEmail.values.email}
              onChange={formikEmail.handleChange}
              error={formikEmail.errors.email}
              isTouched={formikEmail.touched.email}
              onBlur={formikEmail.handleBlur}
            />
          )
          : null}
      {
        pageNumber === 2
          ? (
            <GetCodePage
              onSubmit={onChangePage}
              email={currentEmail.current}
              onReSendCode={formikEmail.handleSubmit}
            />
          )
          : null}
      {
        pageNumber === 3
          ? (
            <PutNewPasswordPage
              onSubmit={onChangePage}
              email={currentEmail.current}
            />
          )
          : null}
      {
        pageNumber === 4
          ? <ChangedPasswordPage />
          : null
      }
    </>
  );
};

export default ResetPasswordPage;
