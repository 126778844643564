import React from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import GhostButton from '../GhostButton';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-bin.svg';
import StyledChangeAvatarArea from './ChangeAvatarArea.styles';

const MAX_AVATAR_SIZE = 2097152;

type PropsType = {
  avatarSrc?: string;
  handleChangeAvatar: (file: File) => void;
  handleDeleteAvatar: () => void;
};

const ChangeAvatarArea: React.FC<PropsType> = (props) => {
  const onClickChangeAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const currentFile = e.target.files[0];
      if (currentFile.size <= MAX_AVATAR_SIZE) {
        props.handleChangeAvatar(currentFile);
      } else {
        toast.error('The photo is larger than the specified size');
      }
    }
  };

  return (
    <StyledChangeAvatarArea>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 1, sm: 5 }, justifyContent: 'center', alignItems: 'center' }}>
        <div className="styled-change-avatar-area__avatar-image">
          {!!props?.avatarSrc &&
            <img src={props?.avatarSrc} alt="avatar" />
          }
        </div>
        <div className="styled-change-avatar-area__avatar-info">
          <div>
            <div>
              <GhostButton type="button">
                <label>
                  <input type="file" accept=".jpg,.png" onChange={onClickChangeAvatar} className="styled-change-avatar-area__avatar-info__input-file" />
                  <p className="text-3">Upload Profile</p>
                  <PlusIcon />
                </label>
              </GhostButton>
            </div>
            {!!props?.avatarSrc &&
              (
                <div onClick={props.handleDeleteAvatar}>
                  <p className="text-3">Delete</p>
                  <TrashIcon className="styled-icon" />
                </div>
              )}
          </div>
          <p className="text-2">max size 2MB, file type .JPG or .PNG</p>
        </div>
      </Box>
    </StyledChangeAvatarArea>
  );
};

export default ChangeAvatarArea;
