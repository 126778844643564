import { Card, Typography, Box, type SxProps } from '@mui/material';

type PropsType = {
  title: string;
  children?: JSX.Element | undefined;
  sx?: SxProps | undefined;
};

const PageTitle: React.FC<PropsType> = (props) => {
  return (
    <Card sx={{ p: 3, borderRadius: 3 }}>
      <Box sx={props.sx}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            fontSize: '24px',
            fontWeight: 900,
            letterSpacing: '1px',
          }}
        >
          {props.title}
        </Typography>
        {props.children}
      </Box>
    </Card>
  );
};

export default PageTitle;
