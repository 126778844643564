import styled from 'styled-components';

export default styled.div`
  background: #26262680;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: ${({ theme }) => theme.mainTheme.color.grey.dark};

  .styled-modal__container {
    width: 100%;
    max-width: 833px;
    background-color: ${({ theme }) => theme.mainTheme.color.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 48px;
    gap: 48px;
    border-radius: ${({ theme }) => theme.borderRadius};

    &__text {
      width: 100%;
      text-align: center;
    }

    &__buttons-area {
      display: flex;
      column-gap: 32px;
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: start;
      row-gap: 48px;

      :last-child {
        align-items: flex-end;
      }

      &__input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: start;
        column-gap: 8px;

        > div {
          max-width: 305px;
          width: 100%;
        }
      }
    }
  }
`;
