export const passwordCheckedPoints = (password: string) => {
  const smallLetters = '([a-z]+)';
  const bigLetters = '([A-Z]+)';
  const numbers = '([0-9]+)';
  const passwordPoints = [];
  passwordPoints[0] = password.length >= 8;
  passwordPoints[1] = !!(password.match(smallLetters) ||
  password.match(bigLetters));
  passwordPoints[2] = !!password.match(numbers);
  return passwordPoints;
};
