import styled, { css } from 'styled-components';

export default styled.label<{ checked: boolean }>`
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, checked }) => css`
    color: ${theme.mainTheme.color.background} !important;
    stroke: ${theme.mainTheme.color.background} !important;
    background: ${checked ? theme.mainTheme.color.red.primary : theme.mainTheme.color.grey.lightBackground};
  `}

  .styled-checkbox__input {
    display: none;
  }

  .styled-checkbox__icon {
    object-fit: cover;
    stroke: ${({ theme }) => theme.mainTheme.color.background};
  }
`;
